import {Button, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, LinkOverlay, LinkBox} from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {Organization} from "../../../utils/apiTypes";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";

function OrganizationList({
                        organizations,
                        onDelete,
                        onEdit,
                        onView
                    }: {
    organizations: Organization[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
}) {
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Type</Th>
                        <Th>Contact Name</Th>
                        <Th>Contact Email</Th>
                        <Th>Contact Phone</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {organizations.map(organization => (
                        <Tr key={organization.id}>
                            <Td px={2} py={1} >{organization.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(organization.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {organization?.name}
                                    </LinkOverlay>
                                </LinkBox>
                                </Td>
                            <Td px={2} py={1}>{organization?.type}</Td>
                            <Td px={2} py={1}>{organization?.contactFirstName} {organization?.contactLastName}</Td>
                            <Td px={2} py={1}>{organization?.contactEmail}</Td>
                            <Td px={2} py={1}>{organization?.contactPhone}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Organization"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(organization.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Organization"
                                    message="Are you sure you want to delete this organization?"
                                    onDelete={() => onDelete(organization.id)}
                                    label="Delete Organization"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default OrganizationList;
