import {Button, FormControl, FormLabel, Input, Box, Text, Select} from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Season} from "../../../utils/apiTypes";
import {useSeason} from "../../Contexts/SeasonContext";

type OrganizationFormProps = {
    onSubmit: (organization: OrganizationData) => void;
    initialData?: OrganizationData;
    onCancel: () => void;
};

type OrganizationData = {
    id?: number;
    name: string;
    type: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
    client: Client;
};

const OrganizationForm: React.FC<OrganizationFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [name, setName] = React.useState(initialData?.name || "");
    const [type, setType] = React.useState(initialData?.type || "");
    const [contactFirstName, setContactFirstName] = React.useState(initialData?.contactFirstName || "");
    const [contactLastName, setContactLastName] = React.useState(initialData?.contactLastName || "");
    const [contactEmail, setContactEmail] = React.useState(initialData?.contactEmail || "");
    const [contactPhone, setContactPhone] = React.useState(initialData?.contactPhone || "");
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { season } = useSeason();

    const validate = (): boolean => {
        if (!name.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        // if (!type.trim()) {
        //     setValidationError("Type cannot be blank");
        //     return false;
        // }
        // if (!contactFirstName.trim()) {
        //     setValidationError("Contact First Name cannot be blank");
        //     return false;
        // }
        // if (!contactLastName.trim()) {
        //     setValidationError("Contact Last Name cannot be blank");
        //     return false;
        // }
        // if (!contactEmail.trim()) {
        //     setValidationError("Contact Email cannot be blank");
        //     return false;
        // }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: OrganizationData = {
                ...initialData,
                name: name,
                type: type,
                contactFirstName: contactFirstName,
                contactLastName: contactLastName,
                contactEmail: contactEmail,
                contactPhone: contactPhone,
                client: client!,

            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="School">School</option>
                        <option value="Club">Club</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Contact First Name</FormLabel>
                    <Input value={contactFirstName} onChange={e => setContactFirstName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Contact Last Name</FormLabel>
                    <Input value={contactLastName} onChange={e => setContactLastName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Contact Email</FormLabel>
                    <Input value={contactEmail} onChange={e => setContactEmail(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Contact Phone</FormLabel>
                    <Input value={contactPhone} onChange={e => setContactPhone(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default OrganizationForm;
