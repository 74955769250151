import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import ClientManagement from "./Client/ClientManagement";
import UserProfile from "./UserProfile";
import UserManagement from "./User/UserManagement";
import ClientSelector from "./ClientSelector";
import {useAuth} from "../Auth/AuthContext";
import {useClient} from "../Contexts/ClientContext";
import Login from "../Auth/Login";
import LoginButton from "../Auth/LoginButton";

const AdminPortal: React.FC = () => {
    const { currentUser, token } = useAuth();
    const { client, setClient } = useClient();
    return (
        <Flex direction="column" height="100vh">
            {/* Header */}
            <LoginButton/>
            <Flex justify="space-between" align="center" bg="gray.200" p={4}>
                <Heading size="md">Admin Portal</Heading>
                {token ?  <UserProfile currentUser={currentUser}/> : null}
            </Flex>
            <Flex flex="1" overflowY="auto">
            <VStack bg="gray.700" padding="10" spacing="6" alignItems="start" width="20%">
                {currentUser ? <ClientSelector user={currentUser} /> : null}
                <Button as={Link} to="/admin/" colorScheme="teal" variant="ghost" width="100%">Dashboard</Button>
                <Button as={Link} to="/admin/clients" colorScheme="teal" variant="ghost" width="100%">Clients</Button>
                <Button as={Link} to="/admin/users" colorScheme="teal" variant="ghost" width="100%">Users</Button>
                <Button as={Link} to="/admin/settings" colorScheme="teal" variant="ghost" width="100%">Settings</Button>
                {/* Add more navigation links as required */}
            </VStack>

            {/* Content Area */}
            <Box flex="1" overflowY="auto" padding="30px">
                <Routes>
                    <Route path={"/clients"} element={<ClientManagement />} />
                    <Route path={"/users"} element={<UserManagement />} />
                    {/* Other sub-routes for AdminPortal, e.g.:
                        <Route path="/users" element={<ManageUsers />} />
                        <Route path="/settings" element={<Settings />} />
                    */}
                </Routes>
            </Box>
        </Flex>
        </Flex>
    );
}

export default AdminPortal;
