import React, { useState, useEffect } from 'react';
import {
    Select, Box, Text, VStack,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
    Button, useDisclosure
} from '@chakra-ui/react';
import {Link} from "react-router-dom";
import {apiCall} from "../../utils/apiUtility";
import {User} from "firebase/auth";
import {Client} from "../../utils/apiTypes";


const ClientSelector: React.FC<{ user: User }> = ({ user }) => {
    const [clients, setClients] = useState<Client[]>([]);
    const [currentClientId, setCurrentClientId] = useState<string | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        async function loadClients() {
            try {
                const fetchedClients = await apiCall<Client[]>('/api/v1/admin/clients', user);
                setClients(fetchedClients);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        }

        loadClients();

        const storedClientId = sessionStorage.getItem('currentClientId');
        if (storedClientId) {
            setCurrentClientId(storedClientId);
        }
    }, [user]);

    const handleClientChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedClientId = event.target.value;
        setCurrentClientId(selectedClientId);
        sessionStorage.setItem('currentClientId', selectedClientId);
    };

    return (
        <>
            <Button onClick={onOpen} colorScheme="teal" variant="ghost" width="100%">Select Client</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Client</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="start">
                            <Box>
                                <Text fontSize="xl" fontWeight="semibold">
                                    Choose a client from the list:
                                </Text>
                            </Box>
                            <Select
                                variant="filled"
                                size="lg"
                                value={currentClientId || ''}
                                onChange={handleClientChange}
                            >
                                {clients.map(client => (
                                    <option key={client.id} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </Select>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ClientSelector;
