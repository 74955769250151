import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Box,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody
} from "@chakra-ui/react";
import React, {Dispatch, SetStateAction, useState} from "react";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useSeason} from "../../Contexts/SeasonContext";
import {useClient} from "../../Contexts/ClientContext";
import {useOrganization} from "../../Contexts/OrganizationContext";
import {Game} from "../../../utils/apiTypes";
import {gamesQueryMap} from "../../../utils/queryMapUtil";


interface GameUploadButtonProps {
    seasonId: number;
    setGames: Dispatch<SetStateAction<Game[]>>;
}

const GameUploadButton: React.FC<GameUploadButtonProps> = ({ seasonId, setGames }) => {
    const [file, setFile] = useState<File | null>(null);
    const {season} = useSeason()
    const {client} = useClient()
    const {organization } = useOrganization()

    const { currentUser } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [apiResult, setApiResult] = useState<String | null>(null);
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };
    const handleApiResult = (result: String) => {
        setApiResult(result);
        onOpen(); // Open the modal when setting the API result
    };

    const queryMap = gamesQueryMap(client, organization, season)
    const fetchGames = () => {
        apiCall<Game[]>(`/api/v1/admin/games/`, currentUser!, 'GET', null, queryMap)
            .then(gamesData => {
                setGames(gamesData);
            })
            .catch(error => {
                console.error('Error fetching games:', error);
            });
    };
    const handleFileUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const url = `/api/v1/admin/games/uploadgames/`
                apiCall<String>(url, currentUser!, 'POST', formData, queryMap)
                .then((result) => {
                    handleApiResult(result); // Handle API result and open the modal
                    fetchGames();
                })
                .catch(error => {
                    console.error('Error uploading games:', error);
                });
        }
    };


    return (
        <>
            <div>
                <Input type="file" onChange={onFileChange} />
                <Button onClick={handleFileUpload}>Upload Games</Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>API Result</ModalHeader>
                    <ModalCloseButton onClick={onClose} /> {/* Close the modal on click */}
                    <ModalBody>{apiResult}</ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default GameUploadButton;
