// components/UserManagement.tsx
import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import UserForm from "./UserForm";
import UserList from "./UserList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useSeason} from "../../Contexts/SeasonContext";

import {Client, User} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import {usersQueryMap} from "../../../utils/queryMapUtil";
import {useOrganization} from "../../Contexts/OrganizationContext";

function UserManagement() {
    const [users, setUsers] = React.useState<User[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();
    const { organization , setOrganization} = useOrganization();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);

    React.useEffect(() => {
        if (currentUser) {
            const queryMap = usersQueryMap(client, organization);
            apiCall<User[]>('/api/v1/admin/users', currentUser, "GET", null, queryMap)
                .then(usersData => setUsers(usersData))
                .catch(error => console.error('Error fetching users:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateUser = (user: { id?: number; firstName: string; lastName: string; email: string; password: string; role: string; client: Client}) => {
        console.log("Received user for update:", user);
        if (currentUser) {
            const method = user.id ? 'PUT' : 'POST';
            const url = user.id ? `/api/v1/admin/users/${user.id}` : '/api/v1/admin/users';

                apiCall<User>(url, currentUser, method, user)
                .then(updatedUser => {
                    if(user.id) {
                        // If updating, find the index and replace the user in the list
                        const updatedUsers = users.map(c => c.id === user.id ? updatedUser : c);
                        setUsers(updatedUsers);
                    } else {
                        // If creating, add the user to the list
                        setUsers([...users, updatedUser]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating user:', error));
        }
    };


    const handleDeleteUser = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/users/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setUsers(users.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting user:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewUser = (id: number) => {
        const userToView = users.find(user => user.id === id);
        setSelectedUser(userToView || null);
        setMode("view");
    };
    // const handleViewUser = (id: number) => {
    //     if (currentUser) {
    //             apiCall<User>(`/api/v1/admin/users/${id}`, currentUser, 'GET')
    //             .then(userData => {
    //                 // Set user ID in the session
    //                 navigate('/user/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch user data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Users</Heading>
                    <UserList
                        users={users}
                        onDelete={handleDeleteUser}
                        onEdit={(id: number) => {
                            const userToEdit = users.find(c => c.id === id) || null;
                            setSelectedUser(userToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewUser}
                    />
                    <Button onClick={() => setMode("create")}>Add New User</Button>
                </>
            )}
            {mode === "create" && (
                <UserForm onSubmit={handleCreateUpdateUser} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedUser && (
                <UserForm onSubmit={handleCreateUpdateUser} onCancel={() => setMode('list')} initialData={selectedUser} />
            )}
            {mode === "view" && selectedUser && (
                <Box>
                    <Heading>User Details</Heading>
                    <Text><strong>Name:</strong> {selectedUser.email}</Text>
                    {/* Display other properties of the user as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default UserManagement;
