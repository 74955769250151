import React, { createContext, useContext, ReactNode } from 'react';
import { Season } from "../../utils/apiTypes";

interface SeasonContextProps {
    season?: Season;
    setSeason: (season: Season) => void;
}

const SeasonContext = createContext<SeasonContextProps | undefined>(undefined);

interface SeasonProviderProps {
    children: ReactNode;
}

export const SeasonProvider: React.FC<SeasonProviderProps> = ({ children }) => {
    const initialSeason = localStorage.getItem('seasonData')
        ? JSON.parse(localStorage.getItem('seasonData') as string)
        : undefined;
    const [season, setSeasonState] = React.useState<Season | undefined>(initialSeason);

    const setSeason = (season: Season) => {
        localStorage.setItem('seasonData', JSON.stringify(season));
        setSeasonState(season);
    };

    return (
        <SeasonContext.Provider value={{ season, setSeason }}>
            {children}
        </SeasonContext.Provider>
    );
};

export const useSeason = (): SeasonContextProps => {
    const context = useContext(SeasonContext);
    if (!context) {
        throw new Error("useSeason must be used within a SeasonProvider");
    }
    return context;
};
