import {Button, FormControl, FormLabel, Input, Box, Text, Select, Switch} from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Season, Team, GameLocation, Game, Page} from "../../../utils/apiTypes";
import {useSeason} from "../../Contexts/SeasonContext";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {gamesQueryMap} from "../../../utils/queryMapUtil";
import {useOrganization} from "../../Contexts/OrganizationContext";

type GameFormProps = {
    onSubmit: (game: GameData) => void;
    initialData?: Game;
    onCancel: () => void;
};

type GameData = {
    id?: number;
    date: string;
    time: string;
    status: string;
    notes: string;
    homeTeamId: number;
    homeTeamScore: number;
    awayTeamId: number;
    awayTeamScore: number;
    locationId: number;
    client: Client;
    seasonId: number;
    isLeagueGame: boolean;
    isPlayoffGame: boolean;
    countsInStandings: boolean;
};

const GameForm: React.FC<GameFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [date, setDate] = React.useState(initialData?.date || "");
    const [time, setTime] = React.useState(initialData?.time || "");
    const [status, setStatus] = React.useState(initialData?.status || "");
    const [notes, setNotes] = React.useState(initialData?.notes || "");
    const [homeTeam, setHomeTeam] = React.useState(initialData?.homeTeam || null);
    const [awayTeam, setAwayTeam] = React.useState(initialData?.awayTeam || null);
    const [homeTeamScore, setHomeTeamScore] = React.useState(initialData?.homeTeamScore || 0);
    const [awayTeamScore, setAwayTeamScore] = React.useState(initialData?.awayTeamScore || 0);
    const [gameLocation, setGameLocation] = React.useState(initialData?.location || null);
    const [isLeagueGame, setIsLeagueGame] = React.useState(initialData?.isLeagueGame || false);
    const [isPlayoffGame, setIsPlayoffGame] = React.useState(initialData?.isLeagueGame || false);
    const [countsInStandings, setCountsInStandings] = React.useState(initialData?.countsInStandings || false);
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { season } = useSeason();
    const { organization } = useOrganization();
    const { currentUser } = useAuth();

    const [teams, setTeams] = React.useState<Team[]>([]);
    const [gameLocations, setGameLocations] = React.useState<GameLocation[]>([]);


    React.useEffect(() => {
        const queryMap = gamesQueryMap(client, undefined, season);
        async function fetchData() {
            if (currentUser) {
                queryMap.sort = "name"
                const fetchedTeams = await apiCall<Page<Team>>('/api/v1/admin/teams/light', currentUser, "GET", null, queryMap)
                setTeams(fetchedTeams.content);
                const fetchedLocations = await apiCall<GameLocation[]>('/api/v1/admin/locations', currentUser, "GET", null, {"clientId":client?.id})
                setGameLocations(fetchedLocations);
            }
        }
        fetchData();
        }, []);

    const validate = (): boolean => {
        if (!date.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: GameData = {
                ...initialData,
                date: date,
                time: time,
                status: status,
                notes: notes,
                homeTeamId: homeTeam!.id,
                awayTeamId: awayTeam!.id,
                homeTeamScore: homeTeamScore,
                awayTeamScore: awayTeamScore,
                locationId: gameLocation!.id,
                client: client!,
                seasonId: season!.id,
                isLeagueGame: isLeagueGame,
                isPlayoffGame: isPlayoffGame,
                countsInStandings: countsInStandings,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select value={status} onChange={e => setStatus(e.target.value)}>

                        <option key="SCHED" value="SCHED">Scheduled</option>
                        <option key="INPROG" value="INPROG">In Progress</option>
                        <option key="COMP" value="COMP">Complete</option>
                        <option key="DUPE" value="DUPE">Possible Duplicate</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Input type="date" value={date} onChange={e => setDate(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Time</FormLabel>
                    <Input type="time"  value={time} onChange={e => setTime(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Notes</FormLabel>
                    <Input value={notes} onChange={e => setNotes(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Home Team</FormLabel>
                    <Select value={homeTeam?.id} onChange={e => setHomeTeam(teams.find(t => t.id === parseInt(e.target.value)) || null)}>
                        {teams.map(team => (
                            <option key={team.id} value={team.id}>{team.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Home Team Score</FormLabel>
                    <Input value={homeTeamScore} onChange={e => setHomeTeamScore(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>Away Team</FormLabel>
                    <Select value={awayTeam?.id} onChange={e => setAwayTeam(teams.find(t => t.id === parseInt(e.target.value)) || null)}>
                        {teams.map(team => (
                            <option key={team.id} value={team.id}>{team.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Away Team Score</FormLabel>
                    <Input value={awayTeamScore} onChange={e => setAwayTeamScore(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Is League Game
                    </FormLabel>
                    <Switch id="is-league-game" isChecked={isLeagueGame} onChange={e => setIsLeagueGame(e.target.checked)} />
                </FormControl>

                <FormControl>
                    <FormLabel>
                        Counts in Standings
                    </FormLabel>
                    <Switch id="counts-in-standings" isChecked={countsInStandings} onChange={e => setCountsInStandings(e.target.checked)} />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Is Playoff Game
                    </FormLabel>
                    <Switch id="is-playoff-game" isChecked={isPlayoffGame} onChange={e => setIsPlayoffGame(e.target.checked)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Location</FormLabel>
                    <Select value={gameLocation?.id} onChange={e => setGameLocation(gameLocations.find(l => l.id === parseInt(e.target.value)) || null)}>
                        {gameLocations.map(loc => (
                            <option key={loc.id} value={loc.id}>{loc.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default GameForm;
