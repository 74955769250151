import { Button, Box, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";


type Client = {
    id: number;
    name: string;
    tenantId: string;
}
    function ClientList({
                            clients,
                            onDelete,
                            onEdit,
                            onView
                        }: {
        clients: Client[];
        onDelete: (id: number) => void;
        onEdit: (id: number) => void;
        onView: (id: number) => void;
    }) {
    return (
        <Box>
            <Table variant="striped" colorScheme="teal">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Tenant Id</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {clients.map(client => (
                        <Tr key={client.id}>
                            <Td>{client.id}</Td>
                            <Td>{client.name}</Td>
                            <Td>{client.tenantId}</Td>
                            <Td>
                                <Button onClick={() => onView(client.id)}>View</Button>
                                <Button onClick={() => onEdit(client.id)}>Edit</Button>
                                <DeleteConfirmation
                                    title="Delete Client"
                                    message="Are you sure you want to delete this client?"
                                    onDelete={() => onDelete(client.id)}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default ClientList;
