import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Box
} from '@chakra-ui/react';
import { ObjectWithKeys } from '../../utils/formatUtils';
interface ISportsTable {
    data?: ObjectWithKeys[] | null
}
export const camelCaseToSentence = (camelCaseString: string) => camelCaseString.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
const SportsTable = ({ data }: ISportsTable) => {
    const stripeColor = useColorModeValue("gray.100", "gray.600");
    if (!data) {
        return <>Loading</>
    }
    if (data.length === 0) {
        return <>Nothing to show</>
    }
    const uniqueKeys = Array.from(new Set(data.flatMap(Object.keys)))
    const headerStyle = { color: "white", padding: "10px" }
    const cellStyle = { border: "1px solid", padding: "10px", borderColor: "gray.400" }
    return (
        <Box overflowX="auto">
            <Table className='sports-table' variant="striped" colorScheme="gray" size="s">
                <Thead bgColor="#202b5f" color="white">
                    <Tr>
                        {uniqueKeys?.map((key) => {
                            return <Th key={key} textTransform="uppercase" {...headerStyle}>{camelCaseToSentence(key)}</Th>
                        }
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {data?.map((record, index) => (
                        <Tr key={index} bgColor={index % 2 === 0 ? stripeColor : "white"}>
                            {uniqueKeys?.map((key) => {
                                return <Td className="cell" data-label={key} {...cellStyle} key={key}>{record[key as keyof typeof record]}</Td>
                            }
                            )}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box >
    );
}
export default SportsTable