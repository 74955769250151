import React from 'react';
import { FormLabel, Select, theme } from '@chakra-ui/react';
import useApi from '../hooks/useApi';
import { Page, TeamGroup } from '../../utils/apiTypes';
import useGenderParams from "../hooks/userGenderParam";

interface IDivisionFilter {
    setter: React.Dispatch<React.SetStateAction<number>>;
    selectedValue: number;
}

const DivisionFilter = ({ setter, selectedValue }: IDivisionFilter) => {
    const { key: gender, title: genderTitle } = useGenderParams();
    const { data: playoffBrackets } = useApi<Page<TeamGroup>>(`api/v1/client/10/teamgroups?sort=name&seasonId=${gender}&groupTypeName=Playoff Bracket`)
    const { data: leagues } = useApi<Page<TeamGroup>>(`api/v1/client/10/teamgroups?sort=name&seasonId=${gender}&groupTypeName=League`)
    const { data: divisions } = useApi<Page<TeamGroup>>(`api/v1/client/10/teamgroups?sort=name&seasonId=${gender}&groupTypeName=Division`)
    return (
        <FormLabel>League, Division or Playoff Bracket
            <Select backgroundColor={theme.colors.white} value={selectedValue} onChange={(e) => {
                setter(Number(e.currentTarget.value))
            }}>
                <option value={-1}>Choose League or Division</option>
                <optgroup label='Playoff Bracket'>
                    {playoffBrackets?.content?.map((playoffBracket) => <option key={playoffBracket.id}
                                                               value={playoffBracket.id}>{playoffBracket.name}</option>)}
                </optgroup>
                <optgroup label='Leauges'>
                    {leagues?.content?.map((league) => <option key={league.id}
                                                               value={league.id}>{league.name}</option>)}
                </optgroup>
                <optgroup label='Divisions'>
                    {divisions?.content?.map((division) => <option key={division.id}
                                                                   value={division.id}>{division.name}</option>)}
                </optgroup>
            </Select>
        </FormLabel>
    );
};

export default DivisionFilter;
