import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    useColorModeValue,
    LinkOverlay, LinkBox
} from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {TeamGroup} from "../../../utils/apiTypes";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";


    function TeamGroupList({
                            teamGroups,
                            onDelete,
                            onEdit,
                            onView
                        }: {
        teamGroups: TeamGroup[];
        onDelete: (id: number) => void;
        onEdit: (id: number) => void;
        onView: (id: number, teamGroup: TeamGroup) => void;
    }) {
        const rowHoverBg = useColorModeValue('gray.200', 'gray.700');
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm"> {/* Adjust the size to "sm" */}
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Type</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {teamGroups?.map(teamGroup => (
                        <Tr key={teamGroup.id} _hover={{ bg: rowHoverBg }}>
                            <Td px={2} py={1}>{teamGroup.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => {
                                        console.log("Click event for team group ID:", teamGroup.id);
                                        onView(teamGroup.id, teamGroup);
                                    }}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >{teamGroup?.name}
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>{teamGroup.teamGroupType.name}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit TeamGroup"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(teamGroup.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete TeamGroup"
                                    message="Are you sure you want to delete this teamGroup?"
                                    onDelete={() => onDelete(teamGroup.id)}
                                    label="Delete TeamGroup"/>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default TeamGroupList;
