import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { PopoverIcon } from './PopoverIcon'
import { useNavigate } from 'react-router-dom';
import type { IMenuContent } from './types';

export const CustomPopover = ({ menuContent, title }: IMenuContent) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();
  return (
    <Popover placement='bottom-start' isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger="hover" openDelay={0}>
      <PopoverTrigger>
        <Button rightIcon={<PopoverIcon isOpen={isOpen} />}>{title}</Button>
      </PopoverTrigger>
      <PopoverContent p="2" maxW="fit-content">
        <Stack spacing="0" alignItems="stretch">
          {menuContent.map((item) => (
            <Button key={item.title} variant="tertiary" justifyContent="start" onClick={() => item?.external ? window.location.href = item.path : navigate(item.path)}>
              {item.title}
            </Button>
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  )
}
