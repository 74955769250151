// components/TeamGroupManagement.tsx
import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React, {useState} from "react";
import TeamGroupForm from "./TeamGroupForm";
import TeamGroupList from "./TeamGroupList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useSeason} from "../../Contexts/SeasonContext";

import {Client, Page, Season, TeamGroup} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import TeamGroupTeamsList from "./TeamGroupTeamsList";

function TeamGroupManagement() {
    const [teamGroups, setTeamGroups] = React.useState<TeamGroup[]>([]);
    const [selectedTeamGroup, setSelectedTeamGroup] = React.useState<TeamGroup | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();
    const { season , setSeason} = useSeason();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);

    React.useEffect(() => {
        if (currentUser) {
            apiCall<Page<TeamGroup>>('/api/v1/admin/teamgroups', currentUser)
                .then(teamGroupsData => setTeamGroups(teamGroupsData.content))
                .catch(error => console.error('Error fetching teamGroups:', error));
        }
    }, [currentUser]);

    const [selectedTeamGroupId, setSelectedTeamGroupId] = useState<number | null>(null);

    const handleViewTeamGroup = (id: number, teamGroup: TeamGroup) => {
        setSelectedTeamGroupId(id);
        setSelectedTeamGroup(teamGroup)
        setMode("view"); // Ensure this mode change is necessary for your logic
    };

    const handleCreateUpdateTeamGroup = (teamGroup: { id?: number; name: string; client: Client; season: Season}) => {
        if (currentUser) {
            const method = teamGroup.id ? 'PUT' : 'POST';
            const url = teamGroup.id ? `/api/v1/admin/teamgroups/${teamGroup.id}` : '/api/v1/admin/teamgroups';

                apiCall<TeamGroup>(url, currentUser, method, teamGroup)
                .then(updatedTeamGroup => {
                    if(teamGroup.id) {
                        // If updating, find the index and replace the teamGroup in the list
                        const updatedTeamGroups = teamGroups.map(c => c.id === teamGroup.id ? updatedTeamGroup : c);
                        setTeamGroups(updatedTeamGroups);
                    } else {
                        // If creating, add the teamGroup to the list
                        setTeamGroups([...teamGroups, updatedTeamGroup]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating teamGroup:', error));
        }
    };

    const handleBackToList = () => {
        setMode("list");
    };

    const handleDeleteTeamGroup = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/teamgroups/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setTeamGroups(teamGroups.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting teamGroup:', error));
        }
    };

    const navigate = useNavigate();
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>TeamGroups</Heading>
                    <TeamGroupList
                        teamGroups={teamGroups}
                        onDelete={handleDeleteTeamGroup}
                        onEdit={(id: number) => {
                            const teamGroupToEdit = teamGroups.find(c => c.id === id) || null;
                            setSelectedTeamGroup(teamGroupToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewTeamGroup}
                    />
                    <Button onClick={() => setMode("create")}>Add New TeamGroup</Button>
                </>
            )}
            {mode === "create" && (
                <TeamGroupForm onSubmit={handleCreateUpdateTeamGroup} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedTeamGroup && (
                <TeamGroupForm onSubmit={handleCreateUpdateTeamGroup} onCancel={() => setMode('list')} initialData={selectedTeamGroup} />
            )}
            {mode === "view" && selectedTeamGroupId && (
                <TeamGroupTeamsList teamGroupId={selectedTeamGroupId!}
                                    teamGroupName = {selectedTeamGroup!.name}
                                    onBackToList={handleBackToList} />
            )}
        </VStack>
    );
}

export default TeamGroupManagement;
