import {Button, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, LinkOverlay, LinkBox} from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {Schedule} from "../../../utils/apiTypes";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";

function ScheduleList({
                        schedules,
                        onDelete,
                        onEdit,
                        onView
                    }: {
    schedules: Schedule[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
}) {
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Tenant Id</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {schedules.map(schedule => (
                        <Tr key={schedule.id}>
                            <Td px={2} py={1} >{schedule.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(schedule.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {schedule?.name}
                                    </LinkOverlay>
                                </LinkBox>
                                </Td>
                            <Td px={2} py={1}>{schedule?.client?.name}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Schedule"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(schedule.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Schedule"
                                    message="Are you sure you want to delete this schedule?"
                                    onDelete={() => onDelete(schedule.id)}
                                    label="Delete Schedule"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default ScheduleList;
