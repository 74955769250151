import React, {useEffect} from 'react';

import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import HomePage from "../../pages/HomePage";
import TeamDetailPage from "../../pages/TeamDetailPage";
import StandingsPage from "../../pages/StandingsPage";
import ResultsPage from "../../pages/ResultsPage";
import SchedulePage from "../../pages/SchedulePage";
import Navbar from "../Navbar/index";
import TeamListPage from '../../pages/TeamsPage';


const EndUser: React.FC = () => {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<SchedulePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/teams/:gender" element={<TeamListPage />} />
                <Route path="/team/:gender/:id" element={<TeamDetailPage />} />
                <Route path="/standings/*" element={<StandingsPage />} />
                <Route path="/standings/:gender" element={<StandingsPage />} />
                <Route path="/results/*" element={<ResultsPage />} />
                <Route path="/schedules/*" element={<SchedulePage />} />
                <Route path="/schedules/:gender" element={<SchedulePage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
    );
}

export default EndUser;
