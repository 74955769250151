import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    useColorModeValue,
    LinkOverlay, LinkBox
} from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {GameLocation} from "../../../utils/apiTypes";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";


    function LocationList({
                            gameLocations,
                            onDelete,
                            onEdit,
                            onView
                        }: {
        gameLocations: GameLocation[];
        onDelete: (id: number) => void;
        onEdit: (id: number) => void;
        onView: (id: number) => void;
    }) {
        const rowHoverBg = useColorModeValue('gray.200', 'gray.700');
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm"> {/* Adjust the size to "sm" */}
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>City</Th>
                        <Th>State</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {gameLocations.map(location => (
                        <Tr key={location.id} _hover={{ bg: rowHoverBg }}>
                            <Td px={2} py={1}>{location.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(location.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {location?.name}
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>{location?.city}</Td>
                            <Td px={2} py={1}>{location?.state}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Location"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(location.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Location"
                                    message="Are you sure you want to delete this location?"
                                    onDelete={() => onDelete(location.id)}
                                    label="Delete Location"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default LocationList;
