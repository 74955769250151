import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, SeasonStats, Team} from "../../../utils/apiTypes";

type SeasonStatsFormProps = {
    onSubmit: (seasonStats: SeasonStatsData) => void;
    initialData?: SeasonStats;
    team: Team;
    client: Client;
    onCancel: () => void;
};

type SeasonStatsData = {
    id?: number;
    firstName: string;
    lastName: string;
    number: number | undefined;
    gamesPlayed: number;
    numberOfGoals: number;
    numberOfAssists: number;
    penaltyMinutes: number;
    shots: number;
    goalsAgainst: number;
    client: Client;
    team: Team;
}

const SeasonStatsForm: React.FC<SeasonStatsFormProps> = ({ onSubmit, onCancel,  initialData, team, client }) => {
    const [firstName, setFirstName] = React.useState(initialData?.firstName || "");
    const [lastName, setLastName] = React.useState(initialData?.lastName || "");
    const [number, setNumber] = React.useState(initialData?.number || undefined);
    const [gamesPlayed, setGamesPlayed] = React.useState(initialData?.gamesPlayed || null);
    const [numberOfGoals, setNumberOfGoals] = React.useState(initialData?.numberOfGoals || null);
    const [numberOfAssists, setNumberOfAssists] = React.useState(initialData?.numberOfAssists || null);
    const [penaltyMinutes, setPenaltyMinutes] = React.useState(initialData?.penaltyMinutes || null);
    const [shots, setShots] = React.useState(initialData?.shots || null);
    const [goalsAgainst, setGoaslAgainst] = React.useState(initialData?.goalsAgainst || null);
    const [validationError, setValidationError] = React.useState<string | null>(null);

    const validate = (): boolean => {
        if (!firstName.trim()) {
            setValidationError("First Name cannot be blank");
            return false;
        }
        if (!lastName.trim()) {
            setValidationError("Last Name cannot be blank");
            return false;
        }

        if (!number) {
            setValidationError("Number cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: SeasonStatsData = {
                ...initialData,
                firstName: firstName,
                lastName: lastName,
                number: number,
                gamesPlayed: gamesPlayed!,
                numberOfGoals: numberOfGoals!,
                numberOfAssists: numberOfAssists!,
                penaltyMinutes: penaltyMinutes!,
                goalsAgainst: goalsAgainst!,
                shots:shots!,
                team: team,
                client: client!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Number</FormLabel>
                    <Input type="number" placeholder="0" value={number} onChange={e => setNumber(Number(e.target.value))} />
                </FormControl>

                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input value={firstName} onChange={e => setFirstName(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={lastName} onChange={e => setLastName(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Games Played</FormLabel>
                    <Input type="number" placeholder="0" value={gamesPlayed!} onChange={e => setGamesPlayed(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>Goals</FormLabel>
                    <Input type="number" placeholder="0" value={numberOfGoals!} onChange={e => setNumberOfGoals(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>Assists</FormLabel>
                    <Input type="number" placeholder="0" value={numberOfAssists!} onChange={e => setNumberOfAssists(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>PIM</FormLabel>
                    <Input type="number" placeholder="0" value={penaltyMinutes!} onChange={e => setPenaltyMinutes(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>Shots</FormLabel>
                    <Input type="number" placeholder="0" value={shots!} onChange={e => setShots(Number(e.target.value))} />
                </FormControl>
                <FormControl>
                    <FormLabel>Goals Against</FormLabel>
                    <Input type="number" placeholder="0" value={goalsAgainst!} onChange={e => setNumberOfGoals(Number(e.target.value))} />
                </FormControl>


                {validationError && <Text color="red.500">{validationError}</Text>}

                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default SeasonStatsForm;
