import React, { useState, FormEvent } from 'react';
import { useUser } from '../User/UserContext';
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword
} from 'firebase/auth';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    useToast,
    theme,
    Box
} from '@chakra-ui/react';
import { apiCallNoAuth } from "../../utils/apiUtility";
import { Client } from "../../utils/apiTypes";
import { useClient } from "../Contexts/ClientContext";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const LoginButton: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const auth = getAuth();
    const toast = useToast();
    const navigate = useNavigate();

    const { setRole } = useUser();
    const { client, setClient } = useClient();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            console.log('Logged in User: ', user);

        } else {
            // User is signed out
            console.log('No user is signed in.');
        }
    });

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const client = await apiCallNoAuth<Client>('/api/v1/auth/client', 'POST', { "email": email, "password": password })

            auth.tenantId = client.tenantId
            await signInWithEmailAndPassword(auth, email, password).then(
                userCred => {
                    userCred.user.getIdTokenResult().then(idTokenResult => {
                        const claims = idTokenResult.claims
                        const userRole = claims.role as string
                        setRole(userRole)
                        setClient(client)
                        localStorage.setItem('userRole', userRole);
                    })
                }
            )

            toast({
                title: "Logged In",
                description: "You have successfully logged in",
                status: "success",
                duration: 1500,
                isClosable: true,
            });
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: "Login Attempt Failed",
                    description: error.message,
                    status: "error",
                    duration: 1500,
                    isClosable: true,
                });
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };


    return (
        <>
            <Box color={theme.colors.white}>
                <Link to="/" onClick={onOpen}>Login</Link>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Login</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <FormControl id="email">
                                <FormLabel>Email</FormLabel>
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>

                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Login
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LoginButton;
