import  React, { useEffect, useState } from 'react';
import { Heading, List, ListItem, SimpleGrid, Table, Tbody, Td, Tr, theme } from '@chakra-ui/react';
import SportsTable, { camelCaseToSentence } from '../../components/Table';
import useApi from '../hooks/useApi';
import {IStandings, RosterEntry} from '../../utils/apiTypes';
import DivisionFilter from './DivisionFilter';
import { GenderLinks } from './GenderLinks';
import useGenderParams from '../hooks/userGenderParam';
import { useLocation, useParams } from 'react-router-dom';
const rearrangeTeamStats = (teamStats: IStandings): IStandings => {
    const { teamId, teamName, ...rest } = teamStats; // Omit 'teamId' and 'teamName'
    return { teamName, ...rest }; // Add 'teamName' first with its original value
};
const DISPLAYED_DATA = [
    'teamName',
    'gamesPlayed',
    'wins',
    'losses',
    'ties',
    'goalsFor',
    'goalsAgainst',
    'points',
    'winPercentage',
    'Team',
    'GP',
    'W',
    'L',
    'T',
    'PTS',
    'GF',
    'GA',
    'WIN%',
]

const TeamStandings: React.FC = () => {
    const { key: gender, title: genderTitle } = useGenderParams();
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const teamGroupFromURL = queryParams.get('teamGroup');
    const [teamGroup, setTeamGroup] = useState(teamGroupFromURL ? parseInt(teamGroupFromURL, 10) : -1);

    let requestPath = `api/v1/client/10/stats/group/${teamGroup}${gender > -1 ? `?seasonId=${gender}` : ''}`;
    if (id !== undefined) {
        requestPath = `api/v1/client/10/stats/${id}`;
    }



    const { data } = useApi<IStandings[]>(requestPath)
    const [standings, setStandings] = useState<IStandings[]>();
    const currentPath = location.pathname;

    useEffect(() => {
        if (data && !Array.isArray(data)) {
            if (id !== undefined) {
                // Remap the single item
                const { teamName, gamesPlayed, wins, losses, ties, points, goalsFor, goalsAgainst, winPercentage } = data;
                const remappedData = {
                    "GP": `${gamesPlayed}`,
                    "W": `${wins}`,
                    "L": `${losses}`,
                    "T": `${ties}`,
                    "PTS": `${points}`,
                    "GF": `${goalsFor}`,
                    "GA": `${goalsAgainst}`,
                    "WIN%": `${winPercentage}`,
                    // ... add other properties in the order you want
                } as unknown as IStandings;

                // Optionally, filter the remapped data if necessary
                // const filteredData: IStandings = Object.fromEntries(
                //     Object.entries(remappedData).filter(([key]) => DISPLAYED_DATA.includes(key))
                // ) as unknown as IStandings;

                setStandings([remappedData] );
            }
        }
        else {
            if (data && data?.length) {
                const reordered = data.map((el) => {
                    return rearrangeTeamStats(el);
                })
                reordered?.sort((a, b) => b.points - a.points)
                const recordEntries  = reordered.map((entry) => {
                    const { teamName,  gamesPlayed, wins, losses, ties, points, goalsFor, goalsAgainst, winPercentage } = entry;
                    const recordEntry = {
                        "Team": `${teamName}`,
                        "GP": `${gamesPlayed}`,
                        "W": `${wins}`,  // Combined name
                        "L": `${losses}`,
                        "T": `${ties}`,
                        "PTS": `${points}`,
                        "GF": `${goalsFor}`,
                        "GA": `${goalsAgainst}`,
                        "WIN%":`${winPercentage}`,
                        // ... add other properties in the order you want
                    }
                    return Object.fromEntries(
                        Object.entries(recordEntry)
                    ) as unknown as IStandings
                });
                setStandings(recordEntries);
            }
            else {
                setStandings([]);
            }
        }
    }, [id, data])
    return (
        <>
            <Heading as="h1" fontSize="30px">
                {currentPath.startsWith('/team/') ? 'Record' : 'Standings' }</Heading>
            {!currentPath.startsWith('/team/') &&
                <SimpleGrid minChildWidth='120px' spacing='40px' marginBottom={4} padding={4} backgroundColor={theme.colors.gray[300]}>
                    <DivisionFilter setter={setTeamGroup} selectedValue={teamGroup} />
                    <GenderLinks gender={genderTitle} basePath='/standings' />
                </SimpleGrid >
            }
            <SportsTable data={standings} />
        </>
    );
};

export default TeamStandings;