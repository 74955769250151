import React, {useEffect, useState} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import {useAuth} from "../Auth/AuthContext";
import UserProfile from "../Admin/UserProfile";
import {useClient} from "../Contexts/ClientContext";
import SeasonManagement from "./Season/SeasonManagement";
import TeamManagement from "./Teams/TeamManagement";
import {useSeason} from "../Contexts/SeasonContext";
import {useOrganization} from "../Contexts/OrganizationContext";
import LocationManagement from './Location/LocationManagement';
import TeamGroupManagement from "./TeamGroup/TeamGroupManagement";
import ScheduleManagement from "./Schedule/ScheduleManagement";
import OrganizationManagement from "./Organization/OrganizationManagement";
import GameManagement from "./Game/GameManagement";
import UserManagement from "./User/UserManagement";


const ClientPortal: React.FC = () => {
    const { currentUser, token } = useAuth();
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const {clearOrganization } = useOrganization()

    React.useEffect(() => {
        clearOrganization()
    }, []);

    return (
        <Flex direction="column" height="100vh">
            {/* Header */}
            <Flex justify="space-between" align="center" bg="gray.200" p={4}>
                <Heading size="md">{client?.name}{season?.name && ` - ${season.name}`}</Heading>

                {token ?  <UserProfile currentUser={currentUser}/> : null}
            </Flex>
            <Flex flex="1" overflowY="auto">
                <VStack bg="gray.700" padding="10" spacing="6" alignItems="start" width="20%">
                    <Button as={Link} to="/client/organizations" colorScheme="teal" variant="ghost" width="100%">Organizations</Button>
                    <Button as={Link} to="/client/users" colorScheme="teal" variant="ghost" width="100%">Users</Button>
                    <Button as={Link} to="/client/seasons" colorScheme="teal" variant="ghost" width="100%">Seasons</Button>
                    <Button as={Link} to="/client/teams" colorScheme="teal" variant="ghost" width="100%">Teams</Button>
                    <Button as={Link} to="/client/schedules" colorScheme="teal" variant="ghost" width="100%">Schedule</Button>
                    <Button as={Link} to="/client/games" colorScheme="teal" variant="ghost" width="100%">Games</Button>
                    <Button as={Link} to="/client/locations" colorScheme="teal" variant="ghost" width="100%">Locations</Button>
                    <Button as={Link} to="/client/teamgroups" colorScheme="teal" variant="ghost" width="100%">Team Groups</Button>
                    {/* Add more navigation links as required */}
                </VStack>

                {/* Content Area */}
                <Box flex="1" overflowY="auto" padding="30px">
                    <Routes>
                        <Route path={"seasons"} element={<SeasonManagement />} />
                        <Route path={"organizations"} element={<OrganizationManagement />} />
                        <Route path={"users"} element={<UserManagement />} />
                        <Route path={"locations"} element={<LocationManagement />} />
                        <Route path={"schedules"} element={<ScheduleManagement />} />
                        <Route path={"games"} element={<GameManagement />} />
                        <Route path={"teams"} element={<TeamManagement />} />
                        <Route path={"teamgroups"} element={<TeamGroupManagement />} />
                        {/* Other sub-routes for AdminPortal, e.g.:
                        <Route path="/users" element={<ManageUsers />} />
                        <Route path="/settings" element={<Settings />} />
                    */}
                    </Routes>
                </Box>
            </Flex>
        </Flex>
    );
}

export default ClientPortal;
