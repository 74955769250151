import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBHmAgbt-GVQQlb2Yac1qVkwQdxfmnNE_k",
    authDomain: "in-event-app.firebaseapp.com",
    projectId: "in-event-app",
    storageBucket: "in-event-app.appspot.com",
    messagingSenderId: "1006392962851",
    appId: "1:1006392962851:web:3fd07aafdcdcbbf97b6696",
    measurementId: "G-DM7BWTG1DB"
};

const app: FirebaseApp = initializeApp(firebaseConfig);

export const auth: Auth = getAuth(app);

