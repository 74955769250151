import React, { createContext, useContext, ReactNode } from 'react';
import { Client } from "../../utils/apiTypes";

interface ClientContextProps {
    client?: Client;
    setClient: (client: Client) => void;
}

const ClientContext = createContext<ClientContextProps | undefined>(undefined);

interface ClientProviderProps {
    children: ReactNode;
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
    const initialClient = localStorage.getItem('clientData')
        ? JSON.parse(localStorage.getItem('clientData') as string)
        : undefined;
    const [client, setClientState] = React.useState<Client | undefined>(initialClient);

    const setClient = (client: Client) => {
        localStorage.setItem('clientData', JSON.stringify(client));
        setClientState(client);
    };

    return (
        <ClientContext.Provider value={{ client, setClient }}>
            {children}
        </ClientContext.Provider>
    );
};

export const useClient = (): ClientContextProps => {
    const context = useContext(ClientContext);
    if (!context) {
        throw new Error("useClient must be used within a ClientProvider");
    }
    return context;
};
