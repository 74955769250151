import { Button, Collapse, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { PopoverIcon } from './PopoverIcon'
import type { IMenuContent } from './types';
import { useNavigate } from 'react-router-dom';



interface CustomCollapseProps extends IMenuContent {
    closeDrawer: () => void;
}
export const CustomCollapse = (({ menuContent, title, closeDrawer }: CustomCollapseProps) => {

  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const handleNavigation = (path: string) => {
        // Close the collapse
        onToggle();
        closeDrawer();

      // Navigate to the new path
      if (/^(https?:\/\/|mailto:)/.test(path)) {
          // External URL - navigate in current tab or open a new tab
          window.location.href = path; // or `window.open(path, '_blank')` for new tab
      } else {
          // Internal route - use navigate
          navigate(path);
      }
  }

  return (
    <>
      <Button justifyContent="space-between" variant="tertiary" size="lg" onClick={onToggle}>
        <Text as="span">{title}</Text>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="4">
          {menuContent.map((item) => (
            <Button key={item.title} variant="tertiary" justifyContent="start" onClick={() => handleNavigation(item.path)}>
              {item.title}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </>
  )
})