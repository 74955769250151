import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React, {Dispatch, SetStateAction, useState} from "react";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {Client, Organization, Season} from "../../../utils/apiTypes";
import {organizationsQueryMap} from "../../../utils/queryMapUtil";


interface OrganizationUploadButtonProps {
    season: Season | null | undefined;
    client: Client;

    setOrganizations: Dispatch<SetStateAction<Organization[]>>;
}

const OrganizationUploadButton: React.FC<OrganizationUploadButtonProps> = ({ client, season,  setOrganizations }) => {
    const [file, setFile] = useState<File | null>(null);
    const { currentUser } = useAuth();
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };
    const fetchOrganizations = () => {
        const queryParams = organizationsQueryMap(client, null)
        apiCall<Organization[]>(`/api/v1/admin/organizations/`, currentUser!, 'GET', null, queryParams)
            .then(organizationsData => {
                setOrganizations(organizationsData);
            })
            .catch(error => {
                console.error('Error fetching organizations:', error);
            });
    };

    const handleFileUpload = () => {
        const queryParams = organizationsQueryMap(client, null)
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const url = `/api/v1/admin/organizations/uploadorganizations/`
            apiCall(url, currentUser!, 'POST', formData, queryParams)
                .then(() => {
                    fetchOrganizations();
                })
                .catch(error => {
                    console.error('Error uploading organizations:', error);
                });
        }
    };


    return (
        <div>
            <Input type="file" onChange={onFileChange} />
            <Button onClick={handleFileUpload}>Upload Organizations</Button>
        </div>
    );
}

export default OrganizationUploadButton;
