import React from 'react';
import {
    Flex,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td as ChakraTd,
    TableContainer, Heading
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {RosterEntry, ScoreSheetDTO, Goal} from "../../../utils/apiTypes";

type ScoreSheetProps = {
    data: ScoreSheetDTO;
    // You can add more props like a callback to close the ScoreSheet view
};
const Td = styled(ChakraTd)({
    padding: '2px',  // Adjust padding as needed
    height: '30px',  // Ensure this matches the row height
    lineHeight: '22px',  // Adjust for text line height
    verticalAlign: 'middle',  // Center content vertically
});

const rowStyle = {
    height: '30px', // Fixed height for each row
    lineHeight: '30px', // Ensure text within the cell does not increase the row height
};
const createTableRows = (players: RosterEntry[], totalRows: number = 23) => {
    const rows = [];

    for (let i = 0; i < totalRows; i++) {
        const player = players[i];

        rows.push(
            <Tr key={i} style={rowStyle}>
                <Td w="5%">{player ? player.number : ''}</Td>
                <Td w="90%">{player ? player.firstName + ' ' + player.lastName : ''}</Td>
                <Td w="5%">{player ? player.position : ''}</Td>
            </Tr>
        );
    }

    return rows;
};

const createGoalRows = (goals: Goal[] = [], totalRows: number = 20) => {
    const rows = [];

    for (let i = 0; i < totalRows; i++) {
        const goal = goals.length > i ? goals[i] : null;

        rows.push(
            <Tr key={i} style={rowStyle}>
                <Td>{goal ? goal.team : ''}</Td>
                <Td>{goal ? goal.period : ''}</Td>
                <Td>{goal ? goal.time : ''}</Td>
                <Td>{goal ? goal.scorer : ''}</Td>
                <Td>{goal ? goal.assist1 : ''}</Td>
                <Td>{goal ? goal.assist2 : ''}</Td>
            </Tr>
        );
    }

    return rows;
};
const ScoreSheet: React.FC<ScoreSheetProps> = ({ data }) => {
    const homeTeamRows = createTableRows(data.homeRoster.statLines);
    const awayTeamRows = createTableRows(data.awayRoster.statLines);
    const goalRows = createGoalRows(data.goals);
    const rowHeight = '30px';
    const headerHeight = '60px'; // Adjust based on your header's actual height
    const playerTableHeight = `calc(${rowHeight} * 23 + ${headerHeight})`; // for 23 rows + header
    const goalTableHeight = `calc(${rowHeight} * 20 + ${headerHeight})`;  // for 20 rows + header
    return (
        <Flex direction="row" h="50vh" p={4} >
            {/* Left Column Table */}
            <Box flex="1" mr={2} border="1px" borderColor="gray.200" borderRadius="md" p={2} height={playerTableHeight}>
                <Heading size="md" textAlign={"center"}>Home Team - {data.homeTeam.name}</Heading>
                <TableContainer>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th w="5%">#</Th>
                                <Th w="90%">Player</Th>
                                <Th w="5%">Position</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {homeTeamRows}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Middle Column */}
            <Box flex="1" mx={2} border="1px" borderColor="gray.200" borderRadius="md" p={2} height={goalTableHeight}>
                <Heading size="md">Goals Scored</Heading>
                <TableContainer >
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th>Team</Th>
                                <Th>Per</Th>
                                <Th>T</Th>
                                <Th>G</Th>
                                <Th>A</Th>
                                <Th>A</Th>
                            </Tr>
                        </Thead>
                        <Tbody>{goalRows}</Tbody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Right Column Table */}
            <Box flex="1" ml={2} border="1px" borderColor="gray.200" borderRadius="md" p={2} height={playerTableHeight}>
                <Heading size="md">Away Team - {data.awayTeam.name}</Heading>
                <TableContainer >
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th w="10%">#</Th>
                                <Th w="80%">Player</Th>
                                <Th w="10%">Position</Th>
                            </Tr>
                        </Thead>
                            <Tbody>
                                {awayTeamRows}
                            </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Flex>
    );
};

export default ScoreSheet;