import React from 'react';
import {Box, Flex, Text, Link, Circle, VStack} from '@chakra-ui/react';
import {format, parseISO} from "date-fns";

type TeamScheduleCardProps = {
    gameDate: string;
    gameTime: string;
    location: string;
    team1Name: string;
    team1Id: number;
    team1Score: number;
    team2Name: string;
    team2Id: number;
    team2Score: number;
    status: string;
    notes: string;
    gender: string;
};
function formatDate(dateString: string, timeString: string): string {
    // Parse the date string
    const date = new Date(parseISO(dateString));
    if (isNaN(date.getTime())) {
        // Handle invalid date string
        return 'Invalid Date';
    }

    // Parse the time string
    const time = new Date(`1970-01-01T${timeString}`);
    let invalidTime = false
    let formattedTime = ''
    if (isNaN(time.getTime())) {
        // Handle invalid time string
        invalidTime = true;
        formattedTime = 'TBD'
    }

    // Format the date
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric'};
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(date);

    if (!invalidTime){
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    }
    // Format the time
    return `${formattedDate} - ${formattedTime}`;
}


const TeamScheduleCard: React.FC<TeamScheduleCardProps> = ({
    gameDate,gameTime, location, team1Name, team1Id, team1Score, team2Name, team2Id, team2Score, status, notes, gender
}) => {
    return (
        <Box
            border="1px solid #e1e2e4"
            boxShadow="0 2px 4px 0 rgba(29,45,61,0.14)"
            borderRadius=".25rem"
            minH="90px"
        >
            <Flex justifyContent="space-between"
                h="100%"
                flexDirection={{ base: 'column', md: 'row' }}
                gap={{ base: '0', md: "10px" }}
            >
                <Flex
                    paddingY="10px"
                    gap="10px"
                    width={{ md: "33.333%", base: '100%' }}
                    maxWidth="100%"
                    alignItems="center"
                    border="1px #e1e2e4"
                    borderStyle="none solid none none"
                    background="#F7F7F7"
                    direction="column"
                    alignContent="center"
                    justifyContent="center"
                >
                    <Text as="strong" fontSize="14px" lineHeight="19px" color="#1D2D3D">{formatDate(gameDate, gameTime)}</Text>
                    <Text fontSize="14px" lineHeight="21px" fontWeight="400">{location}</Text>
                </Flex>
                <VStack gap={{ base: "15px", md: "0" }}
                        justify="center"
                        align="center"
                        paddingY="20px"
                        width="100%">
                <Flex
                    direction={{ base: "column", md: "row" }}
                    gap={{ base: "15px", md: "0" }}
                    justify="center"
                    align="center"
                    paddingY="20px"
                    width="100%">
                    <Flex flex={1}
                        gap="15px"
                        justifyContent="flex-end"
                        alignItems="center"
                        pr={{ base: "0", md: "15px" }}>
                        <Text
                            fontSize="18px"
                            lineHeight="24px"
                            fontWeight="bold"
                            color={team1Name ? "#1D2D3D" : "#737e88"}
                            fontStyle={team1Name ? "none" : "italic"}
                        >{team1Name ?
                            <Link href={`/team/${gender}/${team1Id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                {team1Name}
                            </Link>
                            : "TBD"
                        }
                        </Text>
                        {status == "COMP" &&
                            <Text
                                fontSize="30px"
                                lineHeight="39px"
                                fontWeight="bold"
                                color=" #1D2D3D"
                            >{team1Score}</Text>
                        }
                    </Flex>
                    <Circle
                        size="40px"
                        border="1px solid #e1e2e4"
                        fontSize="16px"
                        lineHeight="21px"
                        fontWeight="bold"
                        color="#DE5252"
                    >
                        VS
                    </Circle>
                    <Flex flex={1}
                        gap="15px"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexDirection={{ base: "row-reverse", md: "row" }}
                        pl={{ base: "0", md: "15px" }}>
                        {status == "COMP" &&
                            <Text
                                fontSize="30px"
                                lineHeight="39px"
                                fontWeight="bold"
                                color=" #1D2D3D"
                            >{team2Score}</Text>
                        }
                        <Text
                            fontSize="18px"
                            lineHeight="24px"
                            fontWeight="700"
                            color={team2Name ? "#1D2D3D" : "#737e88"}
                            fontStyle={team2Name ? "none" : "italic"}
                        >
                            {team2Name ?
                                <Link href={`/team/${gender}/${team2Id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {team2Name}
                                </Link>
                                : "TBD"
                            }
                        </Text>
                    </Flex>
                </Flex>
                    {notes && (
                        <Text
                            fontSize="14px"
                            textAlign="center"
                            w="full"
                            py={0}
                        >
                            {notes}
                        </Text>
                    )}
                </VStack>
            </Flex>
        </Box>
    );
};

export default TeamScheduleCard;