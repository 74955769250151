// components/LocationManagement.tsx
import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import LocationForm from "./LocationForm";
import LocationList from "./LocationList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useSeason} from "../../Contexts/SeasonContext";

import {Client, GameLocation} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import GameUploadButton from "../Game/GameUploadButton";
import LocationUploadButton from "./LocationUploadButton";

function LocationManagement() {
    const [gameLocations, setGameLocations] = React.useState<GameLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = React.useState<GameLocation | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();
    const { season , setSeason} = useSeason();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);

    React.useEffect(() => {
        if (currentUser) {
            apiCall<GameLocation[]>('/api/v1/admin/locations', currentUser)
                .then(locationsData => setGameLocations(locationsData))
                .catch(error => console.error('Error fetching locations:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateLocation = (location: { id?: number; name: string; client: Client}) => {
        if (currentUser) {
            const method = location.id ? 'PUT' : 'POST';
            const url = location.id ? `/api/v1/admin/locations/${location.id}` : '/api/v1/admin/locations';

                apiCall<GameLocation>(url, currentUser, method, location)
                .then(updatedLocation => {
                    if(location.id) {
                        // If updating, find the index and replace the location in the list
                        const updatedLocations = gameLocations.map(c => c.id === location.id ? updatedLocation : c);
                        setGameLocations(updatedLocations);
                    } else {
                        // If creating, add the location to the list
                        setGameLocations([...gameLocations, updatedLocation]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating location:', error));
        }
    };


    const handleDeleteLocation = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/locations/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setGameLocations(gameLocations.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting location:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewLocation = (id: number) => {
        const locationToView = gameLocations.find(gameLocation => gameLocation.id === id);
        setSelectedLocation(locationToView || null);
        setMode("view");
    };
    // const handleViewLocation = (id: number) => {
    //     if (currentUser) {
    //             apiCall<Location>(`/api/v1/admin/locations/${id}`, currentUser, 'GET')
    //             .then(locationData => {
    //                 // Set location ID in the session
    //                 navigate('/location/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch location data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Locations</Heading>
                    <LocationList
                        gameLocations={gameLocations}
                        onDelete={handleDeleteLocation}
                        onEdit={(id: number) => {
                            const locationToEdit = gameLocations.find(c => c.id === id) || null;
                            setSelectedLocation(locationToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewLocation}
                    />
                    <Button onClick={() => setMode("create")}>Add New Location</Button>
                    <LocationUploadButton setGameLocations={setGameLocations} />
                </>
            )}
            {mode === "create" && (
                <LocationForm onSubmit={handleCreateUpdateLocation} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedLocation && (
                <LocationForm onSubmit={handleCreateUpdateLocation} onCancel={() => setMode('list')} initialData={selectedLocation} />
            )}
            {mode === "view" && selectedLocation && (
                <Box>
                    <Heading>Location Details</Heading>
                    <Text><strong>Name:</strong> {selectedLocation.name}</Text>
                    {/* Display other properties of the location as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default LocationManagement;
