import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Season} from "../../../utils/apiTypes";
import {useSeason} from "../../Contexts/SeasonContext";

type ScheduleFormProps = {
    onSubmit: (schedule: ScheduleData) => void;
    initialData?: ScheduleData;
    onCancel: () => void;
};

type ScheduleData = {
    id?: number;
    name: string;
    client: Client;
    seasonId: number;
};

const ScheduleForm: React.FC<ScheduleFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [name, setName] = React.useState(initialData?.name || "");
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { season } = useSeason();

    const validate = (): boolean => {
        if (!name.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: ScheduleData = {
                ...initialData,
                name: name,
                client: client!,
                seasonId: season!.id,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default ScheduleForm;
