import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton
} from "@chakra-ui/react";
import React from "react";
import {DeleteIcon} from "@chakra-ui/icons";

type DeleteConfirmationProps = {
    title: string;
    message: string;
    onDelete: () => void;
    label: string;
};

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ title, message, label, onDelete }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <IconButton
                onClick={onOpen}
                aria-label={label}
                icon={<DeleteIcon />}
                size="sm"
                variant="ghost"/>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {message}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={() => { onDelete(); onClose(); }}>
                            Delete
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteConfirmation