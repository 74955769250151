// apiUtility.ts
import axios, {AxiosError} from 'axios';
import qs from 'qs';

interface User {
    getIdToken(forceRefresh?: boolean): Promise<string>;
}
interface ErrorResponse {
    message?: string;
    // you can add other properties you expect from an error response
}

const urlRoot = process.env.REACT_APP_API_BASE_URL;
export async function apiCall<T>(
    endpoint: string,
    user: User,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    data?: any,
    queryParams: Record<string, any> = {}
): Promise<T> {

    const token = await user.getIdToken(true);
    try {
        const response = await axios({
            method,
            url: `${urlRoot}${endpoint}`,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data,
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;  // Type casting to get TypeScript to understand the error structure
        if (axiosError && axiosError.response) {
            // Server responded with a status other than 200 range
            if (axiosError.response.data && axiosError.message) {
                throw new Error(axiosError.message);  // Use the server's error message
            } else {
                throw new Error('An error occurred while processing your request.');  // Generic error message
            }
        } else if (axiosError.request) {
            // The request was made but no response was received
            throw new Error('No response received from the server. Please try again later.');
        } else {
            // Something happened while setting up the request
            throw new Error('An error occurred while setting up the request.');
        }
    }
}

export async function apiCallNoAuth<T>(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    data?: any
): Promise<T> {
    const response = await axios({
        method,
        url: `${urlRoot}${endpoint}`,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        data,
    });
    return response.data;
}

export async function apiUploadCall<T>(
    endpoint: string,
    user: User,
    method: 'POST',
    data?: any,
    queryParams: Record<string, any> = {}

): Promise<T> {
    const token = await user.getIdToken(true);
    try {
        const response = await axios({
            method,
            url: `${urlRoot}${endpoint}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data,
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;  // Type casting to get TypeScript to understand the error structure
        if (axiosError && axiosError.response) {
            // Server responded with a status other than 200 range
            if (axiosError.response.data && axiosError.message) {
                throw new Error(axiosError.message);  // Use the server's error message
            } else {
                throw new Error('An error occurred while processing your request.');  // Generic error message
            }
        } else if (axiosError.request) {
            // The request was made but no response was received
            throw new Error('No response received from the server. Please try again later.');
        } else {
            // Something happened while setting up the request
            throw new Error('An error occurred while setting up the request.');
        }
    }
}