import { IconButton, Box, Table, Thead, Tbody, Tr, Th, Td, Button, LinkBox, LinkOverlay, useColorModeValue } from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons"; // Ensure you have these icons
import React, {useState} from "react";
import {SeasonStats} from "../../../utils/apiTypes";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";


function SeasonStatsList({
                        seasonStatsArray,
                        onDelete,
                        onEdit,
                        onView
                    }: {
    seasonStatsArray: SeasonStats[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
}) {
    const rowHoverBg = useColorModeValue('gray.200', 'gray.700');
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm"> {/* Adjust the size to "sm" */}
                <Thead>
                    <Tr>
                        <Th px={2} py={1}>Number</Th>
                        <Th px={2} py={1}>Name</Th>
                        <Th px={2} py={1}>GP</Th>
                        <Th px={2} py={1}>G</Th>
                        <Th px={2} py={1}>A</Th>
                        <Th px={2} py={1}>Pts</Th>
                        <Th px={2} py={1}>PIM</Th>
                        <Th px={2} py={1}>Shots</Th>
                        <Th px={2} py={1}>GA</Th>
                        <Th px={2} py={1}>SV%</Th>
                        <Th px={2} py={1}>GAA</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {seasonStatsArray.map(seasonStats => (
                        <Tr key={seasonStats.id} _hover={{ bg: rowHoverBg }}>
                            <Td px={2} py={1}>{seasonStats.number}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(seasonStats.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {seasonStats.firstName} {seasonStats.lastName}
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>{seasonStats.gamesPlayed}</Td>
                            <Td px={2} py={1}>{seasonStats.numberOfGoals}</Td>
                            <Td px={2} py={1}>{seasonStats.numberOfAssists}</Td>
                            <Td px={2} py={1}>{seasonStats.points}</Td>
                            <Td px={2} py={1}>{seasonStats.penaltyMinutes}</Td>
                            <Td px={2} py={1}>{seasonStats.shots}</Td>
                            <Td px={2} py={1}>{seasonStats.goalsAgainst}</Td>
                            <Td px={2} py={1}>{seasonStats.savePercentage}</Td>
                            <Td px={2} py={1}>{seasonStats.goalsAgainstAverage}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit SeasonStats"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(seasonStats.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete SeasonStats"
                                    message="Are you sure you want to delete this seasonStats?"
                                    onDelete={() => onDelete(seasonStats.id)}
                                    label="Delete SeasonStats"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>

            </Table>
        </Box>
    );
}

export default SeasonStatsList;
