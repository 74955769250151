
import { useParams } from 'react-router-dom';

const seasonMap = {
    men: parseInt(process.env.REACT_APP_BOYS_SEASON_ID!),
    women: parseInt(process.env.REACT_APP_GIRLS_SEASON_ID!),
}
const useGenderParams = () => {
    const { gender } = useParams<{ gender?: 'men' | 'women' }>();
    return {key: seasonMap[gender || 'men'], title: gender || 'men'};
};

export default useGenderParams;
export {seasonMap}
