import React, { useEffect, useState } from 'react';
import { Heading } from '@chakra-ui/react';
import SportsTable from '../../components/Table';
import useApi from '../hooks/useApi';
import { RosterEntry } from '../../utils/apiTypes';
import { useParams } from 'react-router-dom';

const HIDDEN_ATTRS = ['id', 'lastTeam', 'heightFeet', 'heightInches', 'weight', 'dateOfBirth', 'phone', 'email'];

const TeamRoster: React.FC = () => {
    const { id } = useParams();
    const { data: rosterData } = useApi<RosterEntry[]>(`api/v1/client/10/players/?teamId=${id}`);
    const [filteredData, setFilteredData] = useState(rosterData);

    useEffect(() => {
        if (rosterData) {
            // Filter out entries with hidden attributes
            const filteredEntries = rosterData.map((entry) => {
                const { firstName, lastName, id, number, homeTown, position, graduationYear, lastTeam } = entry;

                const orderedEntry = {
                    "#": `${number}`,
                    name: `${firstName} ${lastName}`,  // Combined name
                    position,
                    year: `${graduationYear ? graduationYear : "" }`,
                    town: `${homeTown ? homeTown : "" }`,
                    // ... add other properties in the order you want
                };

                return Object.fromEntries(
                    Object.entries(orderedEntry).filter(([key]) => !HIDDEN_ATTRS.includes(key))
                ) as unknown as RosterEntry
            });

            setFilteredData(filteredEntries);
        }
    }, [rosterData]);

    return (
        <>
            <Heading size="md" marginBottom="20px">
                Roster
            </Heading>
            <SportsTable data={filteredData} />
        </>
    );
};

export default TeamRoster;
