// components/UserForm.tsx

import {Button, FormControl, FormLabel, Input, Box, Select} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Client, User} from "../../../utils/apiTypes";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";


type UserFormProps = {
    onSubmit: (user: User) => void;
    initialData?: User;
};

const UserForm: React.FC<UserFormProps> = ({ onSubmit,  initialData }) => {

    const [clients, setClients] = useState<Client[]>([]); // List of all clients, fetched from your API
    const [selectedClientId, setSelectedClientId] = useState<number | null>(initialData?.client?.id || null); // Selected client's ID

    const [email, setEmail] = useState<string>(initialData?.email || '');
    const [password, setPassword] = useState<string>('');
    const [firstName, setFirstName] = useState<string>(initialData?.firstName ||'');
    const [lastName, setLastName] = useState<string>(initialData?.lastName ||'');
    const [role, setRole] = useState<string>(initialData?.role ||'User'); // Default to 'User'
    const [client , setClient] = useState<Client | null>(initialData?.client || null); // Default to 'User'
    const { currentUser } = useAuth();

    useEffect(() => {

            if (currentUser) {
                apiCall<Client[]>('/api/v1/admin/clients', currentUser)
                    .then(clientsData => {
                        setClients(clientsData)
                        if (clientsData.length === 1) {
                            setSelectedClientId(clientsData[0].id);
                        }
                    })
                    .catch(error => console.error('Error fetching clients:', error));
            }
    }, []);
    const handleSubmit = () => {
        const selectedClient = clients.find(c => c.id === selectedClientId);

        if (!selectedClient) {
            // Handle the error: maybe show a notification to the user
            console.error("Selected client not found!");
            return;
        }

        const formData: User = {
            ...initialData,
            email: email,
            firstName: firstName,
            lastName: lastName,
            password: password,
            role: role,
            client: selectedClient
        };
        onSubmit(formData);
    };

return (
    <Box>
        <form
            onSubmit={e=> {
                e.preventDefault();
                handleSubmit();
            }}>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={e => setEmail(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input value={firstName} onChange={e => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input value={lastName} onChange={e => setLastName(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Role</FormLabel>
                <Input value={role} onChange={e => setRole(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Client</FormLabel>
                <Select
                    value={selectedClientId || undefined}
                    onChange={e => setSelectedClientId(Number(e.target.value))}>
                    <option value="" disabled>Select a client</option>
                    {clients.map(client =>
                        <option key={client.id} value={client.id}>{client.name}</option>
                    )}
                </Select>
            </FormControl>
            <Button mt={4} type="submit">
                Submit
            </Button>
        </form>
    </Box>
);
}


export default UserForm;
