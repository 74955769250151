import React, {useEffect, useState} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import {useAuth} from "../Auth/AuthContext";
import UserProfile from "../Admin/UserProfile";
import {useClient} from "../Contexts/ClientContext";
import TeamManagement from "../Client/Teams/TeamManagement";
import {useSeason} from "../Contexts/SeasonContext";
import {useOrganization} from "../Contexts/OrganizationContext";
import ScheduleManagement from "../Client/Schedule/ScheduleManagement";
import SeasonManagement from "../Client/Season/SeasonManagement";
import GameManagement from "../Client/Game/GameManagement";


const OrganizationPortal: React.FC = () => {
    const { currentUser, token } = useAuth();
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { organization } = useOrganization();


    return (
        <Flex direction="column" height="100vh">
            {/* Header */}
            <Flex justify="space-between" align="center" bg="gray.200" p={4}>
                <Heading size="md">{organization?.name}{season?.name && ` - ${season.name}`}</Heading>

                {token ?  <UserProfile currentUser={currentUser}/> : null}
            </Flex>
            <Flex flex="1" overflowY="auto">
                <VStack bg="gray.700" padding="10" spacing="6" alignItems="start" width="20%">
                    <Button as={Link} to="/organization/dashboard" colorScheme="teal" variant="ghost" width="100%">Dashboard</Button>
                    <Button as={Link} to="/organization/seasons" colorScheme="teal" variant="ghost" width="100%">Seasons</Button>
                    <Button as={Link} to="/organization/teams" colorScheme="teal" variant="ghost" width="100%">Teams</Button>
                    <Button as={Link} to="/organization/schedules" colorScheme="teal" variant="ghost" width="100%">Schedules</Button>
                    <Button as={Link} to="/organization/games" colorScheme="teal" variant="ghost" width="100%">Games</Button>
                    {/* Add more navigation links as required */}
                </VStack>

                {/* Content Area */}
                <Box flex="1" overflowY="auto" padding="30px">
                    <Routes>
                        <Route path={"teams"} element={<TeamManagement />} />
                        <Route path={"seasons"} element={<SeasonManagement />} />
                        <Route path={"schedules"} element={<ScheduleManagement />} />
                        <Route path={"games"} element={<GameManagement />} />

                        {/* Other sub-routes for AdminPortal, e.g.:
                        <Route path="/users" element={<ManageUsers />} />
                        <Route path="/settings" element={<Settings />} />
                    */}
                    </Routes>
                </Box>
            </Flex>
        </Flex>
    );
}

export default OrganizationPortal;
