import React from 'react';
import { FormLabel, Input } from '@chakra-ui/react';

interface IDatePicker {
    setDate: React.Dispatch<React.SetStateAction<string>>;
    selectedDate: string;
}

const DatePicker = ({ setDate, selectedDate }: IDatePicker) => {
    return (
        <FormLabel>Date Picker
            <Input
                type="date"
                value={selectedDate}
                onChange={(e) => setDate(e.target.value)}
                backgroundColor="white"
            />
        </FormLabel>
    );
};

export default DatePicker;