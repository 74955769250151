// components/TeamGroupForm.tsx

import { Button, FormControl, FormLabel, Input, Box, Text, Select } from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Season, TeamGroupType} from "../../../utils/apiTypes";
import {circOut} from "framer-motion";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useSeason} from "../../Contexts/SeasonContext";

type TeamGroupFormProps = {
    onSubmit: (teamGroup: TeamGroupData) => void;
    initialData?: TeamGroupData;
    onCancel: () => void;
};

type TeamGroupData = {
    id?: number;
    name: string;
    client: Client;
    season : Season;
    teamGroupType: TeamGroupType;
};

const TeamGroupForm: React.FC<TeamGroupFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { season } = useSeason();
    const { currentUser } = useAuth();
    const [name, setName] = React.useState(initialData?.name || "");
    const [teamGroupTypes, setTeamGroupTypes] = React.useState<TeamGroupType[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [teamGroupType, setTeamGroupType] = React.useState<TeamGroupType | null>(null);


    React.useEffect(() => {
        // Fetch the TeamGroupType list here and set it to the state
        if (currentUser){
        apiCall<TeamGroupType[]>('/api/v1/admin/teamgrouptypes', currentUser)
            .then(data => {
                setTeamGroupTypes(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch TeamGroupTypes:', error);
                setIsLoading(false);
            });
        }
    }, []);


    const validate = (): boolean => {
        if (!name.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: TeamGroupData = {
                ...initialData,
                name: name,
                client: client!,
                season: season!,
                teamGroupType: teamGroupType!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Team Group Type</FormLabel>
                    <Select
                        value={teamGroupType ? teamGroupType.id : ""}
                        onChange={e => {
                            if (e.target.value) {
                                const selectedType = teamGroupTypes.find(type => type.id === parseInt(e.target.value));
                                setTeamGroupType(selectedType!);
                            } else {
                                setTeamGroupType(null);
                            }
                        }}
                        isDisabled={isLoading}
                    >
                        <option value="" disabled>Select a Team Group Type</option>
                        {teamGroupTypes.map(type => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                        ))}
                    </Select>
                </FormControl>

                {validationError && <Text color="red.500">{validationError}</Text>}

                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default TeamGroupForm;
