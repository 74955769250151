// components/ClientManagement.tsx
import { Box, Button, VStack, Heading } from "@chakra-ui/react";
import React from "react";
import ClientForm from "./ClientForm";
import ClientList from "./ClientList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {Route, Routes, useNavigate} from "react-router-dom";

import {Client} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import UserManagement from "../User/UserManagement";
import SeasonManagement from "../../Client/Season/SeasonManagement";

function ClientManagement() {
    const [clients, setClients] = React.useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();

    React.useEffect(() => {
        if (currentUser) {
            apiCall<Client[]>('/api/v1/admin/clients', currentUser)
                .then(clientsData => setClients(clientsData))
                .catch(error => console.error('Error fetching clients:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateClient = (client: { id?: number; name: string; tenantId: string; }) => {
        console.log("Received client for update:", client);
        if (currentUser) {
            const method = client.id ? 'PUT' : 'POST';
            const url = client.id ? `/api/v1/admin/clients/${client.id}` : '/api/v1/admin/clients';

                apiCall<Client>(url, currentUser, method, client)
                .then(updatedClient => {
                    if(client.id) {
                        // If updating, find the index and replace the client in the list
                        const updatedClients = clients.map(c => c.id === client.id ? updatedClient : c);
                        setClients(updatedClients);
                    } else {
                        // If creating, add the client to the list
                        setClients([...clients, updatedClient]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating client:', error));
        }
    };


    const handleDeleteClient = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/clients/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setClients(clients.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting client:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewClient = (id: number) => {
        if (currentUser) {
                apiCall<Client>(`/api/v1/admin/clients/${id}`, currentUser, 'GET')
                .then(clientData => {
                    // Set client ID in the session
                    sessionStorage.setItem('currentClient', id.toString());

                    setClient(clientData);
                    navigate('/client/');
                })
                .catch(error => {
                    console.error("Failed to fetch client data", error);
                });
        }
    };

    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Clients</Heading>
                    <ClientList
                        clients={clients}
                        onDelete={handleDeleteClient}
                        onEdit={id => {
                            const clientToEdit = clients.find(c => c.id === id) || null;
                            setSelectedClient(clientToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewClient}
                    />
                    <Button onClick={() => setMode("create")}>Add New Client</Button>
                </>
            )}
            {mode === "create" && (
                <ClientForm onSubmit={handleCreateUpdateClient} />
            )}
            {mode === "edit" && selectedClient && (
                <ClientForm onSubmit={handleCreateUpdateClient} initialData={selectedClient} />
            )}
        </VStack>
    );
}

export default ClientManagement;
