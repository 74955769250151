// components/UserManagement.tsx
import { Box, Button, VStack, Heading } from "@chakra-ui/react";
import React from "react";
import UserForm from "./UserForm";
import UserList from "./UserList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {Client, User} from "../../../utils/apiTypes";

function UserManagement() {
    const [users, setUsers] = React.useState<User[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"

    const { currentUser } = useAuth();

    React.useEffect(() => {
        if (currentUser) {
            apiCall<User[]>('/api/v1/admin/users', currentUser)
                .then(usersData => setUsers(usersData))
                .catch(error => console.error('Error fetching users:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateUser = (user: User) => {
        if (currentUser) {
            const method = user.id  ? 'PUT' : 'POST';
            const url = user.id ? `/api/v1/admin/users/${user.id}` : '/api/v1/admin/users';

            apiCall<User>(url, currentUser, method, user)
                .then(updatedUser => {
                    if(user.id) {
                        // If updating, find the index and replace the user in the list
                        const updatedUsers = users.map(c => c.id === user.id ? updatedUser : c);
                        setUsers(updatedUsers);
                    } else {
                        // If creating, add the user to the list
                        setUsers([...users, updatedUser]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating user:', error));
        }
    };


    const handleDeleteUser = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/users/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setUsers(users.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting user:', error));
        }
    };

    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Users</Heading>
                    <UserList
                        users={users}
                        onDelete={handleDeleteUser}
                        onEdit={id => {
                            const userToEdit = users.find(c => c.id === id) || null;
                            console.log("User to edit:", userToEdit?.firstName)
                            setSelectedUser(userToEdit);
                            setMode("edit");
                            console.log("Mode:", mode)
                        }}
                        onView={id => {
                            console.log(`View user with ID: ${id}`);
                        }}
                    />
                    <Button onClick={() => setMode("create")}>Add New User</Button>
                </>
            )}
            {mode === "create" && (
                <UserForm onSubmit={handleCreateUpdateUser} />
            )}
            {mode === "edit" && selectedUser && (
                <UserForm onSubmit={handleCreateUpdateUser} initialData={selectedUser} />
            )}
        </VStack>
    );
}

export default UserManagement;
