import { useState, useEffect } from 'react';

interface ApiHookResult<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
}

const useApi = <T>(url: string): ApiHookResult<T> => {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null); // Explicitly specifying the type here
    const urlRoot = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${urlRoot}/${url}`);
                const result: T = await response.json();
                setData(result);
            } catch (error) {
            // Ensure error is an instance of Error
            if (error instanceof Error) {
                setError(error);
            } else {
                setError(new Error("An unknown error occurred."));
            }
            } finally {
            setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return { data, loading, error };
};

export default useApi;
