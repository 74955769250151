import React from 'react';
import { ITeamData } from './types';
import { Box, List, ListItem, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useGenderParams from "../hooks/userGenderParam";

const listStyle = {
    borderBottom: '1px solid #ddd',
    padding: '5px',
    marginBottom: '5px'
}
const TeamList = ({ content }: ITeamData) => {
    const { key: gender, title: genderTitle } = useGenderParams();
    if (!content?.length) {
        return <>Nothing here</>
    }
    return (
        <Box p="4">
            <List spacing={4}>
                {Object.keys(content).map((team) => (
                    <ListItem key={content[Number(team)].id} {...listStyle}>
                        <Link to={`/team/${genderTitle}/${content[Number(team)].id}`}><Text fontSize="lg">{content[Number(team)].name}</Text></Link>
                    </ListItem>

                ))}
            </List>
        </Box>
    );
};

export default TeamList;
