import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (newPage: number) => {
        if (newPage >= 0 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    return (
        <Flex justify="space-between" align="center" mt="4">
            <Text>
                Page {currentPage + 1} of {totalPages}
            </Text>
            <Box>
                {currentPage > 0 && (
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        mr="2"
                    >
                        Previous
                    </Button>
                )}
                {currentPage + 1 < totalPages && (
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Next
                    </Button>
                )}
            </Box>
        </Flex>
    );
};

export default Pagination;
