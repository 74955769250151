import React from 'react';
import TeamStandings from './components/TeamStandings';
import {Box, Container, Flex, Grid} from '@chakra-ui/react';

const StandingsPage: React.FC = () => {

    return (
        <Grid
            templateColumns={["1fr", "1fr 3fr 1fr"]}
            gap={6}
        >
            <Box gridColumn={["1 / -1", "1 / 2"]} p={4} order={[1, 0]}>
                <a href="https://www.gipper.com/partner-demo-request?Partnerhnibbutton=&utm_source=button&utm_medium=Partner&utm_campaign=hnib&utm_content=partner&utm_term=hnib_button_masshockey_website_logo&query=Partnerhnibbutton"
                   target="_blank" rel="noopener noreferrer" style={{margin: '10px'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Gipper_MassHSHockey_300x300.png"
                         alt="Gipper"/>
                </a>
                <a href="https://uoduckshockey.com/recruits" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/UOREGON-HOCKEY-AD.jpg"
                         alt="Oregon"/>
                </a>
            </Box>
            <Box gridColumn={["1 / -1", "2 / 3"]} p={4} order={[0, 0]} >
                {/* Main Content */}
                <TeamStandings/>
            </Box>
            <Box gridColumn={["1 / -1", "3 / 4"]} p={4} order={[1, 0]}>
                <a href="https://laurastamm.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Laura-Stamm_2021_300px.jpg"
                         alt="Laura Stamm"/>
                </a>
                <a href="https://nswingsgirls.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/NS_Wings_Girls_2023AD-1.png"
                         alt="NS Wings"/>
                </a>
                <a href="http://dynamiksports.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Dynamik-300x250.jpg"
                         alt="Dynamic Sports"/>
                </a>
            </Box>
        </Grid>
    );
};

export default StandingsPage;
