// UserProfile.tsx
import React, { useEffect, useState } from 'react';
import { Box, Collapse, Button, Text } from '@chakra-ui/react';
import {auth} from "../Firebase/Firebase";
import {User} from "firebase/auth";
 // Adjust the path if needed


type UserInfo = {
    email: string;
    name: string;
};

const UserProfile: React.FC<{ currentUser: User | null}> = ({ currentUser }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [user, setUser] = useState<UserInfo | null>(null);

    useEffect(() => {
        // Directly access the currentUser from auth

        if (currentUser) {
            currentUser.getIdTokenResult(true).then((idTokenResult) => {
                // Extract user info from the token result
                const email = currentUser.email || "No Email";
                const name = (idTokenResult.claims.name as string) || "No Name";  // This ensures you have a "name" claim and it's treated as a string
                setUser({ email, name });
            });
        }
    }, []);

    if (!user) return <Box><Text>No User</Text></Box>; // Or some loading state

    return (
        <Box>
            <Button onClick={() => setShowInfo(!showInfo)} variant="link">
                {user.email}
            </Button>

            <Collapse in={showInfo}>
                <Box mt={4}>
                    <Text>Name: {user.name}</Text>
                    <Text>Current Role: {localStorage.getItem("userRole")}</Text>

                </Box>
            </Collapse>
        </Box>
    );
}

export default UserProfile;
