import React, { useState, ChangeEvent } from 'react';
import { FiSearch, FiXCircle } from 'react-icons/fi'
import {
    Box,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    List,
    ListItem,
    Select,
    theme,
} from '@chakra-ui/react';
import { Team } from "../../../utils/apiTypes";
import useApi from '../../../pages/hooks/useApi';
import { Link } from 'react-router-dom';
interface ITeamSearch {
    genderSet?: null | number,
    genderDesc?: null | string
}
const boysSeasonId = parseInt(process.env.REACT_APP_BOYS_SEASON_ID!);
const girlsSeasonId = parseInt(process.env.REACT_APP_GIRLS_SEASON_ID!);

const boysSeasonLabel = process.env.REACT_APP_BOYS_SEASON_LABEL!;
const girlsSeasonLabel = process.env.REACT_APP_GIRLS_SEASON_LABEL!;
const TeamSearchDropdown = ({ genderSet = null, genderDesc = null }: ITeamSearch) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
    const [gender, setGender] = useState(genderSet || process.env.REACT_APP_BOYS_SEASON_ID);
    const [genderLabel, setGenderLabel] = useState(genderDesc || process.env.REACT_APP_BOYS_SEASON_LABEL);


    const { data: teams } = useApi<Team[]>(`api/v1/client/10/teams/search?seasonId=${gender}&name=${searchTerm}`);
    const clearSearch = () => {
        setSearchTerm('');
        setShowAutoComplete(false)
    }
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (value && Array.isArray(teams)) {
            setShowAutoComplete(true);
            const filtered = teams.filter(team =>
                team.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredTeams(filtered);
        } else {
            setShowAutoComplete(false);
            setFilteredTeams([]);
        }
    };
    const handleSetGender = (g: number) => {
        if (g === boysSeasonId){
            setGenderLabel(boysSeasonLabel)
        } else {
            setGenderLabel(girlsSeasonLabel)
        }
        setGender(g)
    };

    return (
        <Box width="300px" margin="0 auto" position="relative" zIndex={1}>
            <InputGroup maxW="2xs" display='inline-flex'>
                {showAutoComplete ? (
                    <InputLeftElement cursor="pointer">
                        <Icon as={FiXCircle} color="fg.accent" fontSize="lg" onClick={clearSearch} />
                    </InputLeftElement>
                ) : (
                    <InputLeftElement >
                        <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />
                    </InputLeftElement>
                )
                }

                <Input
                    placeholder="Search teams"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    borderRadius="10px 0px 0px 10px"
                    borderRight="0px"
                />
                <Select
                    color={theme.colors.white}
                    bg="bg.accent.default"
                    borderRadius="0px 10px 10px 0px"
                    borderLeft="1px solid"
                    value={gender}
                    onChange={(e) => { handleSetGender(Number(e.currentTarget.value)) }}>
                    <option value={boysSeasonId}>Boys</option>
                    <option value={girlsSeasonId}>Girls</option>
                </Select>
                {
                    showAutoComplete && (
                        <List maxHeight="80vh" overflowY="scroll" width="100%" position="absolute" top="34px" backgroundColor={'white'} boxShadow="md" zIndex={999}>
                            {filteredTeams.map((team) => (
                                <Link to={`/team/${genderLabel}/${team.id}`} key={team.id}>
                                    <ListItem _hover={{ bg: "gray.200" }} onClick={clearSearch} key={team.id || team.name} paddingX="4" paddingY="2">
                                        {team.name}
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    )
                }
            </InputGroup>
        </Box >
    );
};

export default TeamSearchDropdown;
