// components/ClientForm.tsx

import { Button, FormControl, FormLabel, Input, Box } from "@chakra-ui/react";
import React from "react";


type ClientFormProps = {
    onSubmit: (client: ClientData) => void;
    initialData?: ClientData;
};

type ClientData = {
    id?: number;
    name: string;
    tenantId: string;
};

const ClientForm: React.FC<ClientFormProps> = ({ onSubmit,  initialData }) => {
    // ... rest of the component
    const [name, setName] = React.useState(initialData?.name || "");
    const [tenantId, setTenantId] = React.useState(initialData?.tenantId || "");

    const handleSubmit = () => {
        const formData: ClientData = {
            ...initialData,
            name: name,
            tenantId: tenantId,
        };
        onSubmit(formData);
    };



return (
    <Box>
        <form
            onSubmit={e=> {
                e.preventDefault();
                handleSubmit();
            }}>
            <FormControl>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={e => setName(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Client Id</FormLabel>
                <Input value={tenantId} onChange={e => setTenantId(e.target.value)} />
            </FormControl>
            <Button mt={4} type="submit">
                Submit
            </Button>
        </form>
    </Box>
);
}


export default ClientForm;
