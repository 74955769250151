import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React, {Dispatch, SetStateAction, useState} from "react";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {Player} from "../../../utils/apiTypes";


interface RosterUploadButtonProps {
    teamId: number;
    setPlayers: Dispatch<SetStateAction<Player[]>>;
}

const RosterUploadButton: React.FC<RosterUploadButtonProps> = ({ teamId, setPlayers }) => {
    const [file, setFile] = useState<File | null>(null);

    const { currentUser } = useAuth();
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };
    const fetchPlayers = () => {
        apiCall<Player[]>(`/api/v1/admin/players/?teamId=${teamId}`, currentUser!, 'GET')
            .then(playersData => {
                setPlayers(playersData);
            })
            .catch(error => {
                console.error('Error fetching players:', error);
            });
    };

    const handleFileUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const url = `/api/v1/admin/teams/uploadRoster/` +teamId
            apiCall(url, currentUser!, 'POST', formData)
                .then(() => {
                    fetchPlayers();
                })
                .catch(error => {
                    console.error('Error uploading the roster:', error);
                });
        }
    };


    return (
        <div>
            <Input type="file" onChange={onFileChange} />
            <Button onClick={handleFileUpload}>Upload Roster</Button>
        </div>
    );
}

export default RosterUploadButton;
