import React, { Dispatch, SetStateAction, useState } from "react";
import {
    Button,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalContent,
    ModalOverlay,
    Modal,
} from "@chakra-ui/react";
import { apiCall } from "../../../utils/apiUtility";
import { useAuth } from "../../Auth/AuthContext";
import { Client, Organization, Season, Team } from "../../../utils/apiTypes";
import { teamsQueryMap } from "../../../utils/queryMapUtil";

interface TeamUploadButtonProps {
    season: Season | undefined | null;
    organization: Organization | undefined | null;
    client: Client;
    setTeams: Dispatch<SetStateAction<Team[]>>;
}

const TeamUploadButton: React.FC<TeamUploadButtonProps> = ({
                                                               client,
                                                               organization,
                                                               season,
                                                               setTeams,
                                                           }) => {
    const [file, setFile] = useState<File | null>(null);
    const { currentUser } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [apiResult, setApiResult] = useState<String | null>(null);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };

    const fetchTeams = () => {
        const queryParams = teamsQueryMap(client, organization, season);
        apiCall<Team[]>(`/api/v1/admin/teams/`, currentUser!, "GET", null, queryParams)
            .then((teamsData) => {
                setTeams(teamsData);
            })
            .catch((error) => {
                console.error("Error fetching teams:", error);
            });
    };

    const handleApiResult = (result: String) => {
        setApiResult(result);
        onOpen(); // Open the modal when setting the API result
    };

    const handleFileUpload = () => {
        if (file) {
            const formData = new FormData();
            const queryParams = teamsQueryMap(client, organization, season);
            formData.append("file", file);
            const url = `/api/v1/admin/teams/uploadteams/`;
            apiCall<String>(url, currentUser!, "POST", formData, queryParams)
                .then((result) => {
                    handleApiResult(result); // Handle API result and open the modal
                    fetchTeams();
                })
                .catch((error) => {
                    console.error("Error uploading teams:", error);
                    handleApiResult(`Error: ${error.message}`); // Show error in modal
                });
        }
    };

    return (
        <>
            <div>
                <Input type="file" onChange={onFileChange} />
                <Button onClick={handleFileUpload}>Upload Teams</Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>API Result</ModalHeader>
                    <ModalCloseButton onClick={onClose} /> {/* Close the modal on click */}
                    <ModalBody>{apiResult}</ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default TeamUploadButton;
