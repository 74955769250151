import { extendTheme } from "@chakra-ui/react";

// Define your color palette
const colors = {
    brand: {
        50: "#d4dbe8",  // Lightest shade, you can adjust it
        100: "#b9c2da", // Lighter shade, you can adjust it
        200: "#9daacc", // Lighter shade, you can adjust it
        300: "#8192bd", // Lighter shade, you can adjust it
        400: "#667aae", // Lighter shade, you can adjust it
        500: "#4a629f", // Default shade, you can adjust it
        600: "#304a90", // Slightly darker, you can adjust it
        700: "#202b5f", // Your specified color
        800: "#1a234a", // Darker shade, you can adjust it
        900: "#141b36", // Darkest shade, you can
    },
    // You can add more color scales here as needed
};

// Extend the theme
const customTheme = extendTheme({ colors });

export default customTheme;
