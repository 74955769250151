import React, { useState } from 'react';
import {Box, Flex, Heading, InputGroup, SimpleGrid, theme} from '@chakra-ui/react';
import TeamScheduleCard from './TeamScheduleCard';
import useApi from '../hooks/useApi';
import { useLocation, useParams } from 'react-router-dom';
import { IPaginatedSchedule } from '../../utils/apiTypes';
import Pagination from './Pagination';
import DivisionFilter from './DivisionFilter';
import useGenderParams from '../hooks/userGenderParam';
import { GenderLinks } from './GenderLinks';
import DatePicker from "./DatePicker";

const smsLinkStyle = {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
};
const TeamSchedule: React.FC = () => {
    const { id } = useParams();
    const { key: gender, title: genderTitle } = useGenderParams();
    // const [filteredTeam, setFilteredTeam] = useState("");
    const [teamGroup, setTeamGroup] = useState(-1);
    const [page, setPage] = useState(0);
    const [selectedDate, setSelectedDate] = useState('');
    const { data: schedule, loading } = useApi<IPaginatedSchedule>(`api/v1/client/10/games?seasonId=${gender}&size=25&sort=date&sort=time${selectedDate ? `&filterDate=${selectedDate}` : ''}${id ? `&teamId=${id}` : ''}&page=${page}${teamGroup !== -1 ? `&teamGroupId=${teamGroup}` : ''}`)
    // const { data: teams } = useApi<IPaginatedSchedule>(`api/v1/client/10/games?seasonId=${gender}&size=25&teamId=${id}&sort=date&page=${page}&teamGroupId=`)
    const location = useLocation();
    const currentPath = location.pathname;
    if (loading || schedule === null) {
        return <>Loading</>
    }

    return (
        <>
            <Box><a href="sms:+9785523955" style={smsLinkStyle}>Text final scores to:</a> 978-552-3955</Box>
            <Box marginTop={4}>** To see <b>MIAA Tournament</b> game results, click the League, Division or Playoff Bracket dropdown and select which tournament you want to view.</Box>
            <Heading as="h1" fontSize="30px">Schedule</Heading>
            {!currentPath.startsWith('/team/') &&
                <InputGroup mb={5} gap="20px" marginBottom={4} padding={4} backgroundColor={theme.colors.gray[300]}>

                    {/* <label>
                        Filter by Team
                        <Select value={filteredTeam} backgroundColor={theme.colors.white} onChange={(e) => setFilteredTeam(e.currentTarget.value)}>
                            <option value="">Filter by Team</option>
                            {teams?.content?.map((teamName, i) => teamName && <option key={i}>{teamName}</option>)}
                        </Select>
                    </label> */}
                    <Flex direction={["column", "row"]} justify="left" align="left">
                        <Box width={["100%", "20%"]} p={4} style={{ verticalAlign: 'top' }}>
                            <GenderLinks gender={genderTitle} basePath='/schedules' />
                        </Box>
                        <Box width={["100%", "40%"]} p={4} style={{ verticalAlign: 'top' }}>
                            <DivisionFilter setter={setTeamGroup} selectedValue={teamGroup} />
                        </Box>
                        <Box width={["100%", "30%"]} p={4} style={{ verticalAlign: 'top' }}>
                            <DatePicker setDate={setSelectedDate} selectedDate={selectedDate} />
                        </Box>
                    </Flex>
                </InputGroup >

            }
            {
                schedule?.content?.length ?
                    <SimpleGrid columns={1} spacing={{ base: 4, md: 2 }
                    } >
                        {schedule.content.map((game, index) => {
                            return (
                                <TeamScheduleCard
                                    key={index}
                                    gameDate={game.date}
                                    gameTime={game.time}
                                    location={game.location}
                                    team1Name={game.homeTeam}
                                    team1Id={game.homeTeamId}
                                    team1Score={game.homeTeamScore || 0}
                                    team2Name={game.awayTeam}
                                    team2Id={game.awayTeamId}
                                    team2Score={game.awayTeamScore || 0}
                                    status={game.status}
                                    notes={game.notes}
                                    gender={genderTitle}
                                />
                            )
                        })}
                        <Pagination currentPage={page} totalPages={schedule.totalPages} onPageChange={setPage} />
                    </SimpleGrid >
                    : <Box textAlign="center"><div>No results</div></Box>}
        </>
    )
};

export default TeamSchedule;
