export const MENS_POPOVER = [
    { title: "Teams", path: "/teams/men" },
    { title: "Schedules", path: "/schedules/men" },
    // { title: "Results", path: "/standings/men" },
    { title: "Standings", path: "/standings/men" },
    { title: "Polls", path: "https://www.hnibnews.com/category/high-school-hockey/massachusetts-high-school-hockey/boys-mass-hs/boys-ma-polls/", external: true },
];
export const WOMENS_POPOVER = [
    { title: "Teams", path: "/teams/women" },
    { title: "Schedules", path: "/schedules/women" },
    // { title: "Results", path: "/standings/women" },
    { title: "Standings", path: "/standings/women" },
    { title: "Polls", path: "https://www.hnibnews.com/category/high-school-hockey/massachusetts-high-school-hockey/girls-mass-hs/girls-ma-polls/", external: true },
]