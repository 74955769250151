import React, { FC, useEffect, useState } from 'react';
import { auth } from './components/Firebase/Firebase';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import AdminPortal from './components/Admin/AdminPortal';
import { User } from 'firebase/auth';
import { AuthProvider } from './components/Auth/AuthContext';
import EndUser from "./components/EndUser/EndUser";
import ClientPortal from "./components/Client/ClientPortal";
import { ClientProvider } from "./components/Contexts/ClientContext";
import { UserProvider } from "./components/User/UserContext";
import { SeasonProvider } from "./components/Contexts/SeasonContext";
import { OrganizationProvider } from "./components/Contexts/OrganizationContext";
import OrganizationPortal from "./components/Organization/OrganizationPortal";

type UserState = {
    user: User | null;
    role: string | null;
};

const GA_MEASUREMENT_ID = 'G-DM7BWTG1DB';
interface CustomWindow extends Window {
    gtag: (...args: any[]) => void;
}

declare let window: CustomWindow;

const trackPageView = (url: string) => {
    // Use `gtag` to track page views in GA4
    window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: url,
    });
};

const RouteChangeListener: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);

    return null;
};

const App: FC = () => {
    const [userState, setUserState] = useState<UserState>({ user: null, role: null });
    const [loading, setLoading] = useState(true);

    const fetchUserRole = async (user: User): Promise<string | null> => {
        const idTokenResult = await user.getIdTokenResult(true);
        return idTokenResult.claims.role as string;
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                const role = await fetchUserRole(userAuth);
                if (role) {
                    localStorage.setItem('userRole', role); // Update the cached role
                    setUserState({ user: userAuth, role: role });
                }
            } else {
                localStorage.removeItem('userRole'); // Remove the cached role if user is not authenticated
                setUserState({ user: null, role: null }); // Reset the user state
            }
            setLoading(false);
        });

        return unsubscribe; // Cleanup the listener on component unmount
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthProvider>
            <ClientProvider>
                <UserProvider>
                    <OrganizationProvider>
                        <SeasonProvider>
                            <Router>
                                <RouteChangeListener/>
                                    <Routes>
                                        <Route path="/admin/*" element={userState.role === 'SuperAdmin' ? <AdminPortal /> : <Navigate to="/" />} />
                                        <Route path="/client/*" element={(userState.role === 'SuperAdmin' || userState.role === 'Admin') ? <ClientPortal /> : <Navigate to="/" />} />
                                        <Route path="/organization/*" element={(userState.role === 'SuperAdmin' || userState.role === 'Admin' || userState.role === 'OrganizationAdmin') ? <OrganizationPortal /> : <Navigate to="/" />} />
                                        <Route path="/*" element={<EndUser />} />
                                    </Routes>
                            </Router>
                        </SeasonProvider>
                    </OrganizationProvider>
                </UserProvider>
            </ClientProvider>
        </AuthProvider>
    );
}

export default App;
