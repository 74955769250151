import { Image, useBreakpointValue } from "@chakra-ui/react";
export const Logo = () => {
  const responsiveSize = useBreakpointValue({ base: "90px", sm: "60px", md: "90px" });
  return (
    <Image
      src="/logo.png"
      alt="Mass High School Hockey Logo"
      maxHeight={responsiveSize}
    />
  )
}
