import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { Auth, getAuth, onAuthStateChanged, User } from 'firebase/auth';

// Define the shape of the context value
interface AuthContextType {
    currentUser: User | null;
    token: string | null;
}

// Define the shape of the AuthProvider props
interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
    const auth: Auth = getAuth();
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        return onAuthStateChanged(auth, async user => {
            setCurrentUser(user);
            if (user) {
                const fetchedToken = await user.getIdToken();
                setToken(fetchedToken);
            } else {
                setToken(null);
            }
        });
    }, [auth]);

    const contextValue = {
        currentUser,
        token
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}
