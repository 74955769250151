export function convertTo12HourTime(time24: string): string {
    const [hourStr, minuteStr] = time24.split(":");
    let hour = parseInt(hourStr, 10);
    const minute = minuteStr || "00";
    let period = "AM";

    if (hour === 0) {
        hour = 12; // 0:00 (midnight) should be 12:00 AM
    } else if (hour >= 12) {
        if (hour > 12) {
            hour -= 12;
        }
        period = "PM";
    }

    return `${hour}:${minute} ${period}`;
}