import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    LinkOverlay,
    LinkBox,
    Text,
    Flex, ListIcon, MenuIcon, TagRightIcon
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {Game, Page, Team} from "../../../utils/apiTypes";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";
import {gamesQueryMap, teamsQueryMap} from "../../../utils/queryMapUtil";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useClient} from "../../Contexts/ClientContext";
import {useSeason} from "../../Contexts/SeasonContext";
import {useOrganization} from "../../Contexts/OrganizationContext";
import {TiChartBar} from "react-icons/ti";

function TeamList({
                        teams,
                        onDelete,
                        onEdit,
                        onView,
                        onShowStats,
                        onSort,
                        onPageChange,
                        currentPage,
                        totalPages
                    }: {
    teams: Team[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
    onShowStats: (id: number) => void;
    onSort: (newSortField: string) => void;
    onPageChange: (newPage: number) => void;
    currentPage : number;
    totalPages: number;
}) {
    const { currentUser } = useAuth(); // Assuming useAuth is availab
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { organization } = useOrganization();

    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {teams?.map(team => (
                        <Tr key={team.id}>
                            <Td px={2} py={1} >{team.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(team.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {team?.name}
                                    </LinkOverlay>
                                </LinkBox>
                                </Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Team"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(team.id)}
                                    size="sm"
                                    variant="ghost" />
                                <IconButton
                                    aria-label="Show Stats"
                                    icon={<TiChartBar/>}
                                    onClick={() => onShowStats(team.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Team"
                                    message="Are you sure you want to delete this team?"
                                    onDelete={() => onDelete(team.id)}
                                    label="Delete Team"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Flex justifyContent="space-between" alignItems="center" mt="4">
                <Button onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 0}>Previous</Button>
                <Text>Page {currentPage + 1} of {totalPages}</Text>
                <Button onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage + 1 === totalPages}>Next</Button>
            </Flex>
        </Box>
    );
}

export default TeamList;
