import React, { useEffect, useState } from 'react';
import { Heading } from '@chakra-ui/react';
import SportsTable from '../../components/Table';
import useApi from '../hooks/useApi';
import { SeasonStats } from '../../utils/apiTypes';
import { useParams } from 'react-router-dom';

const HIDDEN_ATTRS = ['id', 'lastTeam', 'heightFeet', 'heightInches', 'weight', 'dateOfBirth', 'phone', 'email'];

const TeamStats: React.FC = () => {
    const { id } = useParams();
    const { data: rosterData } = useApi<SeasonStats[]>(`api/v1/client/10/seasonstatline?teamId=${id}`);
    const [filteredData, setFilteredData] = useState(rosterData);

    useEffect(() => {
        if (rosterData) {
            // Filter out entries with hidden attributes
            const filteredEntries = rosterData.map((entry) => {
                const { firstName, lastName, id, number, gamesPlayed, points, numberOfGoals, numberOfAssists, shots, goalsAgainst, savePercentage, goalsAgainstAverage} = entry;

                const orderedEntry = {
                    "#": `${number}`,
                    name: `${firstName} ${lastName}`,  // Combined name
                    "GP": `${gamesPlayed}`,
                    "G": `${numberOfGoals}`,
                    "A": `${numberOfAssists}`,
                    "PTS": `${points}`,
                    "Shots": `${shots}`,
                    "GA": `${goalsAgainst}`,
                    "SV%": `${savePercentage}`,
                    "GAA": `${goalsAgainstAverage}`,
                    // ... add other properties in the order you want
                };

                return Object.fromEntries(
                    Object.entries(orderedEntry).filter(([key]) => !HIDDEN_ATTRS.includes(key))
                ) as unknown as SeasonStats
            });

            setFilteredData(filteredEntries);
        }
    }, [rosterData]);

    return (
        <>
            <Heading size="md" marginBottom="20px">
                Stats
            </Heading>
            <SportsTable data={filteredData} />
        </>
    );
};

export default TeamStats;
