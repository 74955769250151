import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import OrganizationForm from "./OrganizationForm";
import OrganizationList from "./OrganizationList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useSeason} from "../../Contexts/SeasonContext";
import {useClient} from "../../Contexts/ClientContext";
import {useOrganization} from "../../Contexts/OrganizationContext";
import {useLocation, useNavigate} from "react-router-dom";
import {Client, Organization, Page, Season} from "../../../utils/apiTypes";
import PlayerManagement from "../Player/PlayerManagement";
import OrganizationUploadButton from "./OrganizationUploadButton";
import {organizationsQueryMap} from "../../../utils/queryMapUtil";


function OrganizationManagement() {
    const [organizations, setOrganizations] = React.useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { currentUser } = useAuth();
    const { organization, setOrganization } = useOrganization();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);
    const queryMap = organizationsQueryMap(client, season)
    React.useEffect(() => {
        if (currentUser) {
            apiCall<Page<Organization>>('/api/v1/admin/organizations', currentUser, "GET", null, queryMap)
                .then(organizationsData => setOrganizations(organizationsData.content))
                .catch(error => console.error('Error fetching organizations:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateOrganization = (organization: { id?: number; name: string; type: string, contactFirstName: string, contactLastName:string,
        contactEmail: string, contactPhone: string, client: Client}) => {
        if (currentUser) {
            const method = organization.id ? 'PUT' : 'POST';
            const url = organization.id ? `/api/v1/admin/organizations/${organization.id}` : '/api/v1/admin/organizations';

            apiCall<Organization>(url, currentUser, method, organization)
                .then(updatedOrganization => {
                    if(organization.id) {
                        // If updating, find the index and replace the organization in the list
                        const updatedOrganizations = organizations.map(c => c.id === organization.id ? updatedOrganization : c);
                        setOrganizations(updatedOrganizations);
                    } else {
                        // If creating, add the organization to the list
                        setOrganizations([...organizations, updatedOrganization]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating organization:', error));
        }
    };


    const handleDeleteOrganization = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/organizations/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setOrganizations(organizations.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting organization:', error));
        }
    };

    const navigate = useNavigate();

    const handleViewOrganization = (id: number) => {
        if (currentUser) {
            apiCall<Organization>(`/api/v1/admin/organizations/${id}`, currentUser, 'GET')
                .then(orgData => {
                    // Set client ID in the session
                    sessionStorage.setItem('currentOrganization', id.toString());
                    setOrganization(orgData)
                    setSelectedOrganization(orgData);
                    navigate('/organization/');
                })
                .catch(error => {
                    console.error("Failed to fetch organization data", error);
                });
        }
    };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Organizations</Heading>
                    <OrganizationList
                        organizations={organizations}
                        onDelete={handleDeleteOrganization}
                        onEdit={(id: number) => {
                            const organizationToEdit = organizations.find(c => c.id === id) || null;
                            setSelectedOrganization(organizationToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewOrganization}
                    />
                    <Button onClick={() => setMode("create")}>Add New Organization</Button>
                    <OrganizationUploadButton season={season} client={client!} setOrganizations={setOrganizations} />
                </>
            )}
            {mode === "create" && (
                <OrganizationForm onSubmit={handleCreateUpdateOrganization} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedOrganization && (
                <OrganizationForm onSubmit={handleCreateUpdateOrganization} onCancel={() => setMode('list')} initialData={selectedOrganization} />
            )}
            {mode === "view" && selectedOrganization && (
                <Box>
                    <Heading>Organization Details</Heading>
                    <Text><strong>Name:</strong> {selectedOrganization.name}</Text>
                    {/* Display other properties of the season as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default OrganizationManagement;
