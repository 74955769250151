import React, { createContext, useContext, ReactNode } from 'react';
import { Organization } from "../../utils/apiTypes";

interface OrganizationContextProps {
    organization?: Organization;
    setOrganization: (organization: Organization) => void;
    clearOrganization: () => void;
}

const OrganizationContext = createContext<OrganizationContextProps | undefined>(undefined);

interface OrganizationProviderProps {
    children: ReactNode;
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({ children }) => {
    const initialOrganization = localStorage.getItem('organizationData')
        ? JSON.parse(localStorage.getItem('organizationData') as string)
        : undefined;
    const [organization, setOrganizationState] = React.useState<Organization | undefined>(initialOrganization);

    const setOrganization = (organization: Organization) => {
        localStorage.setItem('organizationData', JSON.stringify(organization));
        setOrganizationState(organization);
    };

    const clearOrganization = () => {
        localStorage.removeItem('organizationData')
        setOrganizationState(undefined);
    };

    return (
        <OrganizationContext.Provider value={{ organization, setOrganization, clearOrganization }}>
            {children}
        </OrganizationContext.Provider>
    );
};

export const useOrganization = (): OrganizationContextProps => {
    const context = useContext(OrganizationContext);
    if (!context) {
        throw new Error("useOrganization must be used within a OrganizationProvider");
    }
    return context;
};
