// components/SeasonManagement.tsx
import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import SeasonForm from "./SeasonForm";
import SeasonList from "./SeasonList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useSeason} from "../../Contexts/SeasonContext";

import {Client, Season} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import {seasonsQueryMap} from "../../../utils/queryMapUtil";
import {useOrganization} from "../../Contexts/OrganizationContext";

function SeasonManagement() {
    const [seasons, setSeasons] = React.useState<Season[]>([]);
    const [selectedSeason, setSelectedSeason] = React.useState<Season | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();
    const { season , setSeason} = useSeason();
    const { organization , setOrganization} = useOrganization();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);

    React.useEffect(() => {
        if (currentUser) {
            const queryMap = seasonsQueryMap(client, organization);
            apiCall<Season[]>('/api/v1/admin/seasons', currentUser, "GET", null, queryMap)
                .then(seasonsData => setSeasons(seasonsData))
                .catch(error => console.error('Error fetching seasons:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateSeason = (season: { id?: number; name: string; client: Client}) => {
        console.log("Received season for update:", season);
        if (currentUser) {
            const method = season.id ? 'PUT' : 'POST';
            const url = season.id ? `/api/v1/admin/seasons/${season.id}` : '/api/v1/admin/seasons';

                apiCall<Season>(url, currentUser, method, season)
                .then(updatedSeason => {
                    if(season.id) {
                        // If updating, find the index and replace the season in the list
                        const updatedSeasons = seasons.map(c => c.id === season.id ? updatedSeason : c);
                        setSeasons(updatedSeasons);
                    } else {
                        // If creating, add the season to the list
                        setSeasons([...seasons, updatedSeason]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating season:', error));
        }
    };


    const handleDeleteSeason = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/seasons/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setSeasons(seasons.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting season:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewSeason = (id: number) => {
        const seasonToView = seasons.find(season => season.id === id);
        setSelectedSeason(seasonToView || null);
        setSeason(seasonToView!);
        setMode("view");
    };
    // const handleViewSeason = (id: number) => {
    //     if (currentUser) {
    //             apiCall<Season>(`/api/v1/admin/seasons/${id}`, currentUser, 'GET')
    //             .then(seasonData => {
    //                 // Set season ID in the session
    //                 navigate('/season/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch season data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Seasons</Heading>
                    <SeasonList
                        seasons={seasons}
                        onDelete={handleDeleteSeason}
                        onEdit={(id: number) => {
                            const seasonToEdit = seasons.find(c => c.id === id) || null;
                            setSelectedSeason(seasonToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewSeason}
                    />
                    <Button onClick={() => setMode("create")}>Add New Season</Button>
                </>
            )}
            {mode === "create" && (
                <SeasonForm onSubmit={handleCreateUpdateSeason} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedSeason && (
                <SeasonForm onSubmit={handleCreateUpdateSeason} onCancel={() => setMode('list')} initialData={selectedSeason} />
            )}
            {mode === "view" && selectedSeason && (
                <Box>
                    <Heading>Season Details</Heading>
                    <Text><strong>Name:</strong> {selectedSeason.name}</Text>
                    {/* Display other properties of the season as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default SeasonManagement;
