import {
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";


import { Logo } from './Logo'
import { MobileDrawer } from './MobileDrawer'
import { FC } from "react";
import TeamSearchDropdown from '../EndUser/Search/TeamSearchDropdown';
import LoginButton from '../Auth/LoginButton';
import { CustomPopover } from './Popover';
import { MENS_POPOVER, WOMENS_POPOVER } from './menu-data';

const Navbar2: FC = () => {
    const navigate = useNavigate();
    return (
        <Box as="section" >
            <Box borderBottomWidth="1px" bg="bg.accent.default" position="relative" zIndex="tooltip">
                <Container py="4">
                    <HStack justify="space-between" spacing="8">
                        <HStack spacing="10">
                            <HStack spacing="3">
                                <MobileDrawer />
                                <a href="/"><Logo /></a>
                            </HStack>
                            <ButtonGroup
                                size="lg"
                                variant="text.accent"
                                colorScheme="gray"
                                spacing="8"
                                display={{ base: 'none', lg: 'flex' }}
                            >
                                <Button onClick={() => navigate("/schedules/men")}>Schedules</Button>
                                <Button onClick={() => navigate("/standings/men")}>Standings</Button>
                                <CustomPopover menuContent={MENS_POPOVER} title="Men's" />
                                <CustomPopover menuContent={WOMENS_POPOVER} title="Women's" />
                                <Button
                                    as="a"
                                    href="mailto:info@masshshockey.com"
                                    colorScheme="blue" // or any color scheme you prefer
                                >
                                    Contact
                                </Button>
                                <LoginButton />
                            </ButtonGroup>
                        </HStack>
                        <HStack spacing={{ base: '2', md: '4' }}>
                            <TeamSearchDropdown />
                        </HStack>
                    </HStack>
                </Container>
            </Box>
        </Box>
    );
}

export default Navbar2;