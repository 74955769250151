import {Box, Button, VStack, Heading, Text, Input, Select} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import GameForm from "./GameForm";
import GameList from "./GameList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";

import {Client, Game, Page, GameLocation, ScoreSheetDTO, Season, Team} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import PlayerManagement from "../Player/PlayerManagement";
import GameUploadButton from "./GameUploadButton";
import {useSeason} from "../../Contexts/SeasonContext";
import {gamesQueryMap} from "../../../utils/queryMapUtil";
import {useOrganization} from "../../Contexts/OrganizationContext";
import ScoreSheet from "./ScoreSheet";

type GameFormData = {
    id: number;
    homeTeamId: number;
    awayTeamId: number;
    locationId: number;
    date: string;
    time: string;
    isLeagueGame: boolean;
    countsInStandings: boolean;
    season: number;
    client: Client;
}

function GameManagement() {
    const [games, setGames] = useState<Game[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [sortField, setSortField] = useState<string[]>(["date","time"]);
    const [filterDate, setFilterDate] = useState<string>(''); // State for holding the selected date
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [selectedGame, setSelectedGame] = React.useState<Game | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { currentUser } = useAuth();
    const { organization } = useOrganization();
    const location = useLocation();
    const [scoreSheet, setScoreSheet] = useState<ScoreSheetDTO | null>(null);
    const [teams, setTeams] = useState<Team[]>([]);
    const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);

    const [apiError, setApiError] = useState<string | null>(null);
    React.useEffect(() => {
        setMode("list");
    }, [location]);

    const queryMap = gamesQueryMap(client, undefined, season)

    const fetchGames = async () => {
        if (currentUser) {
            queryMap.sort = ['date','time']
            queryMap.direction = 'ASC'
            queryMap.page = currentPage
            queryMap.size = pageSize
            if (filterDate) {
                queryMap.filterDate = filterDate;
            }
            if (selectedTeamId){
                queryMap.teamId = selectedTeamId
            }
            try {
                const gamesData = await apiCall<Page<Game>>(`/api/v1/admin/games`, currentUser, "GET", null, queryMap)
                setGames(gamesData.content);
                setTotalPages(gamesData.totalPages);
                handlePageChange(gamesData.number)
            }
            catch(error){
                console.error('Error fetching games:', error);
            }
        }
    }
    useEffect(() => {
        fetchGames()
    }, [currentUser, currentPage, pageSize, sortField, sortDirection, filterDate, selectedTeamId]);

    const fetchTeams = async () => {
        if (currentUser) {
            if (currentUser) {
                queryMap.sort = "name"
                queryMap.direction = 'ASC'
                queryMap.page = 0
                queryMap.size = 10000
                const fetchedTeams = await apiCall<Page<Team>>('/api/v1/admin/teams/light', currentUser, "GET", null, queryMap)
                setTeams(fetchedTeams.content);}
        }
    };

    useEffect(() => {
        fetchTeams();
    }, [currentUser]); //



    const handleCreateUpdateGame = (game: { id?: number; date: string; time: string; homeTeamId: number, awayTeamId: number;  locationId: number; client: Client, countsInStandings: boolean, isLeagueGame: boolean}) => {
        if (currentUser) {
            const method = game.id ? 'PUT' : 'POST';
            const url = game.id ? `/api/v1/admin/games/${game.id}` : '/api/v1/admin/games';

            apiCall<Game>(url, currentUser, method, game)
                .then(updatedGame => {
                    if(game.id) {
                        // If updating, find the index and replace the game in the list
                        const updatedGames = games.map(c => c.id === game.id ? updatedGame : c);
                        setGames(updatedGames);
                    } else {
                        // If creating, add the game to the list
                        setGames([...games, updatedGame]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating game:', error));
        }
    };

    const handleInitializeGame = async (id: number) => {
        if (currentUser) {
            try {
                const scoreSheetDTO: ScoreSheetDTO = await apiCall<ScoreSheetDTO>(`/api/v1/admin/scoresheets`, currentUser, 'POST', {'gameId':id} );
                setScoreSheet(scoreSheetDTO);
                setMode("scoreSheet");
            } catch (error) {
                console.error('Error initializing game:', error);
                setApiError(error instanceof Error ? error.message : String(error));
            }
        }
    };
    const handleShowScoreSheet = async (id: number) => {
        if (currentUser) {
            try {
                const scoreSheetDTO: ScoreSheetDTO = await apiCall<ScoreSheetDTO>(`/api/v1/admin/scoresheets/${id}`, currentUser, 'GET',  );
                setScoreSheet(scoreSheetDTO);
                setMode("scoreSheet");
            } catch (error) {
                console.error('Error fetching scoreSheet:', error);
                setApiError(error instanceof Error ? error.message : String(error));
            }
        }
    };
    const handleDeleteGame = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/games/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setGames(games.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting game:', error));
        }
    };
    const handleSort = (newSortField: string[]) => {
        setSortField(newSortField);
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    };

    // Handler for pagination
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterDate(event.target.value);
    };

    const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTeamId(Number(event.target.value));
    };

    const clearDateFilter = () => {
        setFilterDate('');
    };


    const navigate = useNavigate();
    const handleViewGame = (id: number) => {
        const gameToView = games.find(game => game.id === id);
        setSelectedGame(gameToView || null);
        setMode("view");
    };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Games</Heading>
                    <Select placeholder="Select a team" onChange={handleTeamChange}>
                        {teams.map(team => (
                            <option key={team.id} value={team.id}>{team.name}</option>
                        ))}
                    </Select>
                    <Input type="date" value={filterDate} onChange={handleDateChange} />
                    <Button onClick={clearDateFilter}>Clear Date</Button>
                    <GameList
                        games={games}
                        onDelete={handleDeleteGame}
                        onEdit={(id: number) => {
                            const gameToEdit = games.find(c => c.id === id) || null;
                            setSelectedGame(gameToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewGame}
                        onInitialize={handleInitializeGame}
                        onShowScoreSheet={handleShowScoreSheet}
                        onSort={handleSort}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                        totalPages={totalPages}
                    />
                    <Button onClick={() => setMode("create")}>Add New Game</Button>
                    <GameUploadButton seasonId={season!.id} setGames={setGames} />
                </>
            )}
            {mode === "create" && (
                <GameForm onSubmit={handleCreateUpdateGame} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedGame && (
                <GameForm onSubmit={handleCreateUpdateGame} onCancel={() => setMode('list')} initialData={selectedGame} />
            )}
            {mode === "view" && selectedGame && (
                <Box>
                    <Heading>Game Details</Heading>
                    <Text><strong>Name:</strong> {selectedGame.homeTeam.name} vs. {selectedGame.awayTeam.name}</Text>
                    {/* Display other properties of the season as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}
            {mode === "scoreSheet" && scoreSheet && (
                <ScoreSheet data={scoreSheet} />
            )}
            {apiError && <div className="error-message">{apiError}</div>}
        </VStack>
    );
}

export default GameManagement;
