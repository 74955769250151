import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import ScheduleForm from "./ScheduleForm";
import ScheduleList from "./ScheduleList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";

import {Client, Schedule, Season} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import PlayerManagement from "../Player/PlayerManagement";
import ScheduleUploadButton from "./ScheduleUploadButton";
import {useSeason} from "../../Contexts/SeasonContext";

function ScheduleManagement() {
    const [schedules, setSchedules] = React.useState<Schedule[]>([]);
    const [selectedSchedule, setSelectedSchedule] = React.useState<Schedule | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { currentUser } = useAuth();
    const location = useLocation();

    React.useEffect(() => {
        setMode("list");
    }, [location]);

    React.useEffect(() => {
        if (currentUser) {
            apiCall<Schedule[]>('/api/v1/admin/schedules', currentUser)
                .then(schedulesData => setSchedules(schedulesData))
                .catch(error => console.error('Error fetching schedules:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateSchedule = (schedule: { id?: number; name: string; client: Client}) => {
        if (currentUser) {
            const method = schedule.id ? 'PUT' : 'POST';
            const url = schedule.id ? `/api/v1/admin/schedules/${schedule.id}` : '/api/v1/admin/schedules';

            apiCall<Schedule>(url, currentUser, method, schedule)
                .then(updatedSchedule => {
                    if(schedule.id) {
                        // If updating, find the index and replace the schedule in the list
                        const updatedSchedules = schedules.map(c => c.id === schedule.id ? updatedSchedule : c);
                        setSchedules(updatedSchedules);
                    } else {
                        // If creating, add the schedule to the list
                        setSchedules([...schedules, updatedSchedule]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating schedule:', error));
        }
    };


    const handleDeleteSchedule = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/schedules/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setSchedules(schedules.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting schedule:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewSchedule = (id: number) => {
        const scheduleToView = schedules.find(schedule => schedule.id === id);
        setSelectedSchedule(scheduleToView || null);
        setMode("view");
    };
    // const handleViewSchedule = (id: number) => {
    //     if (currentUser) {
    //             apiCall<Schedule>(`/api/v1/admin/schedules/${id}`, currentUser, 'GET')
    //             .then(scheduleData => {
    //                 // Set schedule ID in the session
    //                 navigate('/schedule/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch schedule data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Schedules</Heading>
                    <ScheduleList
                        schedules={schedules}
                        onDelete={handleDeleteSchedule}
                        onEdit={(id: number) => {
                            const scheduleToEdit = schedules.find(c => c.id === id) || null;
                            setSelectedSchedule(scheduleToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewSchedule}
                    />
                    <Button onClick={() => setMode("create")}>Add New Schedule</Button>
                    <ScheduleUploadButton seasonId={season!.id} setSchedules={setSchedules} />
                </>
            )}
            {mode === "create" && (
                <ScheduleForm onSubmit={handleCreateUpdateSchedule} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedSchedule && (
                <ScheduleForm onSubmit={handleCreateUpdateSchedule} onCancel={() => setMode('list')} initialData={selectedSchedule} />
            )}
            {mode === "view" && selectedSchedule && (
                <Box>
                    <Heading>Schedule Details</Heading>
                    <Text><strong>Name:</strong> {selectedSchedule.name}</Text>
                    {/* Display other properties of the season as needed */}
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default ScheduleManagement;
