import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Player, Team} from "../../../utils/apiTypes";

type PlayerFormProps = {
    onSubmit: (player: PlayerData) => void;
    initialData?: PlayerData;
    team: Team;
    client: Client;
    onCancel: () => void;
};

type PlayerData = {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    dateOfBirth: string;
    homeTown: string;
    heightFeet: string;
    heightInches: string;
    weight: string;
    number: number;
    shot: string;
    position: string;
    lastTeam: string;
    graduationYear: string;
    client: Client;
    team:Team;
};

const PlayerForm: React.FC<PlayerFormProps> = ({ onSubmit, onCancel,  initialData, team, client }) => {
    const [firstName, setFirstName] = React.useState(initialData?.firstName || "");
    const [lastName, setLastName] = React.useState(initialData?.lastName || "");
    const [email, setEmail] = React.useState(initialData?.email || "");
    const [phone, setPhone] = React.useState(initialData?.phone || "");
    const [dateOfBirth, setDateOfBirth] = React.useState(initialData?.dateOfBirth || "");
    const [homeTown, setHomeTown] = React.useState(initialData?.homeTown || "");
    const [heightFeet, setHeightFeet] = React.useState(initialData?.heightFeet || "");
    const [heightInches, setHeightInches] = React.useState(initialData?.heightInches || "");
    const [weight, setWeight] = React.useState(initialData?.weight || "");
    const [number, setNumber] = React.useState(initialData?.number || undefined);
    const [shot, setShot] = React.useState(initialData?.shot || "");
    const [position, setPosition] = React.useState(initialData?.position || "");
    const [lastTeam, setLastTeam] = React.useState(initialData?.lastTeam || "");
    const [graduationYear, setGraduationYear] = React.useState(initialData?.graduationYear || "");
    const [validationError, setValidationError] = React.useState<string | null>(null);

    const validate = (): boolean => {
        if (!firstName.trim()) {
            setValidationError("First Name cannot be blank");
            return false;
        }
        if (!lastName.trim()) {
            setValidationError("Last Name cannot be blank");
            return false;
        }
        // if (!email.trim()) {
        //     setValidationError("Email cannot be blank");
        //     return false;
        // }
        // if (!phone.trim()) {
        //     setValidationError("Phone Number cannot be blank");
        //     return false;
        // }
        // if (!dateOfBirth.trim()) {
        //     setValidationError("Date of Birth cannot be blank");
        //     return false;
        // }
        // if (!homeTown.trim()) {
        //     setValidationError("Home Town cannot be blank");
        //     return false;
        // }
        // if (!heightFeet.trim()) {
        //     setValidationError("Height in Feet cannot be blank");
        //     return false;
        // }
        // if (!heightInches.trim()) {
        //     setValidationError("Height Inches cannot be blank");
        //     return false;
        // }
        // if (!weight.trim()) {
        //     setValidationError("Weight cannot be blank");
        //     return false;
        // }
        if (!number) {
            setValidationError("Number cannot be blank");
            return false;
        }
        if (!position.trim()) {
            setValidationError("Position cannot be blank");
            return false;
        }
        // if (!lastTeam.trim()) {
        //     setValidationError("Last Team cannot be blank");
        //     return false;
        // }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: PlayerData = {
                ...initialData,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                dateOfBirth: dateOfBirth,
                homeTown: homeTown,
                heightFeet: heightFeet,
                heightInches: heightInches,
                weight: weight,
                shot: shot,
                number: number!,
                position: position,
                lastTeam: lastTeam,
                graduationYear: graduationYear,
                team: team,
                client: client!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Number</FormLabel>
                    <Input type="number" placeholder="0" value={number} onChange={e => setNumber(Number(e.target.value))} />
                </FormControl>

                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input value={firstName} onChange={e => setFirstName(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={lastName} onChange={e => setLastName(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Graduation Year</FormLabel>
                    <Input value={graduationYear} onChange={e => setGraduationYear(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Position</FormLabel>
                    <Input value={position} onChange={e => setPosition(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Shot</FormLabel>
                    <Input value={shot} onChange={e => setShot(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>DOB</FormLabel>
                    <Input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Height (Feet)</FormLabel>
                    <Input value={heightFeet} onChange={e => setHeightFeet(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Height (Inches)</FormLabel>
                    <Input value={heightInches} onChange={e => setHeightInches(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Weight</FormLabel>
                    <Input value={weight} onChange={e => setWeight(e.target.value)} />
                </FormControl>


                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input value={phone} onChange={e => setPhone(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Home Town</FormLabel>
                    <Input value={homeTown} onChange={e => setHomeTown(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Last Team</FormLabel>
                    <Input value={lastTeam} onChange={e => setLastTeam(e.target.value)} />
                </FormControl>

                {validationError && <Text color="red.500">{validationError}</Text>}

                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default PlayerForm;
