import {Button, FormControl, FormLabel, Input, Box, Text, Select, Switch} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, Organization, Page, Season, Team, TeamGroup} from "../../../utils/apiTypes";
import {useSeason} from "../../Contexts/SeasonContext";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";

type TeamFormProps = {
    onSubmit: (team: TeamData) => void;
    initialData?: TeamData;
    onCancel: () => void;
};

type TeamData = {
    id?: number;
    name: string;
    gender: string | undefined | null;
    mascotName: string | undefined | null;
    shortenedTeamName: string | undefined | null;
    abbreviation: string | undefined | null;
    logo: string | undefined | null;
    website: string | undefined | null;
    twitter: string | undefined | null;
    instagram: string | undefined | null;
    facebook: string | undefined | null;
    youTube: string | undefined | null;
    homeRink: string | undefined | null;
    mainColor: string | undefined | null;
    excludeFromStandings: boolean | undefined | null;
    secondaryColor: string | undefined | null;
    headCoach: string | undefined | null;
    headCoachPhone: string | undefined | null;
    headCoachEmail: string | undefined | null;
    teamGroups: TeamGroup[];
    selectedDivision: string | undefined | null;
    selectedLeague: string | undefined | null;
    selectedPlayoffBracket: string | undefined | null;
    organization: Organization
    organizationId: number | undefined
    client: Client;
    season: Season;
};

const TeamForm: React.FC<TeamFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [name, setName] = React.useState(initialData?.name || "");
    const [gender, setGender] = React.useState(initialData?.gender || "");
    const [mascotName, setMascotName] = React.useState(initialData?.mascotName || "");
    const [shortenedTeamName, setShortenedTeamName] = React.useState(initialData?.shortenedTeamName || "");
    const [abbreviation, setAbbreviation] = React.useState(initialData?.abbreviation || "");
    const [logo, setLogo] = React.useState(initialData?.logo || "");
    const [website, setWebsite] = React.useState(initialData?.website || "");
    const [twitter, setTwitter] = React.useState(initialData?.twitter || "");
    const [instagram, setInstagram] = React.useState(initialData?.instagram || "");
    const [facebook, setFacebook] = React.useState(initialData?.facebook || "");
    const [youTube, setYouTube] = React.useState(initialData?.youTube || "");
    const [homeRink, setHomeRink] = React.useState(initialData?.homeRink || "");
    const [mainColor, setMainColor] = React.useState(initialData?.mainColor || "");
    const [secondaryColor, setSecondaryColor] = React.useState(initialData?.secondaryColor || "");
    const [headCoach, setHeadCoach] = React.useState(initialData?.headCoach || "");
    const [headCoachPhone, setHeadCoachPhone] = React.useState(initialData?.headCoachPhone || "");
    const [headCoachEmail, setHeadCoachEmail] = React.useState(initialData?.headCoachEmail || "");
    const [excludeFromStandings, setExcludeFromStandings] = React.useState(initialData?.excludeFromStandings || false);
    const [teamGroups, setTeamGroups] = React.useState(initialData?.teamGroups || []);
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { season } = useSeason();
    const initialLeague = initialData?.teamGroups?.find(group => group.teamGroupType.name === 'League');
    const initialPlayoffBracket = initialData?.teamGroups?.find(group => group.teamGroupType.name === 'Playoff Bracket');
    const initialDivision = initialData?.teamGroups?.find(group => group.teamGroupType.name === 'Division');
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>(initialData?.organization)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(initialData?.organizationId)
    const [divisions, setDivisions] = useState<TeamGroup[]>([]);
    const [leagues, setLeagues] = useState<TeamGroup[]>([]);
    const [playoffBrackets, setPlayoffBrackets] = useState<TeamGroup[]>([]);
    const [selectedDivision, setSelectedDivision] = useState<string | undefined>(initialDivision?.name);
    const [selectedLeague, setSelectedLeague] = useState<string | undefined>(initialLeague?.name);
    const [selectedPlayoffBracket, setSelectedPlayoffBracket] = useState<string | undefined>(initialPlayoffBracket?.name);
    const { currentUser } = useAuth();

    useEffect(() => {
        // Fetch divisions and leagues
        if (currentUser) {
            const fetchTeamGroups = async () => {
                apiCall<Page<TeamGroup>>('/api/v1/admin/teamgroups', currentUser, "GET", null, {'groupTypeName':'Division', 'clientId':client?.id})
                    .then(teamGroupData => {
                        setDivisions(teamGroupData.content);
                    })
                    .catch(error => {
                        console.log('Error fetching divisions')
                    });

                apiCall<Page<TeamGroup>>('/api/v1/admin/teamgroups', currentUser, "GET", null, {'groupTypeName':'League', 'clientId':client?.id})
                    .then(teamGroupData => {
                        setLeagues(teamGroupData.content);
                    })
                    .catch(error => {
                        console.log('Error fetching leagues')
                    });

                apiCall<Page<TeamGroup>>('/api/v1/admin/teamgroups', currentUser, "GET", null, {'groupTypeName':'Playoff Bracket', 'clientId':client?.id})
                    .then(teamGroupData => {
                        setPlayoffBrackets(teamGroupData.content);
                    })
                    .catch(error => {
                        console.log('Error fetching leagues')
                    });
            };
            fetchTeamGroups();
            const fetchOrganizations = async () => {
                apiCall<Page<Organization>>('/api/v1/admin/organizations', currentUser, "GET", null, {'page':0,'size':10000,'sort':'name','seasonId':season?.id, 'clientId':client?.id})
                    .then(organizationData => {
                        setOrganizations(organizationData.content);
                    })
                    .catch(error => {
                        console.log('Error fetching divisions')
                    });
            };
            fetchOrganizations();
        }
    }, []);


    const validate = (): boolean => {
        if (!name.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const selectedOrganization = organizations.find(org => org.id === selectedOrganizationId);
            const formData: TeamData = {
                ...initialData,
                name: name,
                gender: gender,
                mascotName: mascotName,
                shortenedTeamName: shortenedTeamName,
                abbreviation: abbreviation,
                logo: logo,
                website: website,
                twitter: twitter,
                instagram: instagram,
                facebook: facebook,
                youTube: youTube,
                homeRink: homeRink,
                mainColor: mainColor,
                secondaryColor: secondaryColor,
                headCoach : headCoach,
                headCoachEmail: headCoachEmail,
                headCoachPhone: headCoachPhone,
                excludeFromStandings: excludeFromStandings,
                teamGroups: teamGroups,
                selectedDivision: selectedDivision,
                selectedLeague: selectedLeague,
                selectedPlayoffBracket: selectedPlayoffBracket,
                organization: selectedOrganization!,
                organizationId: selectedOrganizationId!,
                client: client!,
                season: season!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>Organization</FormLabel>
                    <Select value={selectedOrganizationId} onChange={e => setSelectedOrganizationId(Number(e.target.value))} placeholder="Select Organization">
                        {organizations?.map(organization => (
                            <option key={organization.id} value={organization.id}>{organization.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Team Name</FormLabel>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <Select
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                        placeholder="Select gender">
                        <option value="Boys">Boys</option>
                        <option value="Girls">Girls</option>
                        <option value="Other">Other</option>
                    </Select>
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Division</FormLabel>
                    <Select value={selectedDivision} onChange={e => setSelectedDivision(e.target.value)} placeholder="Select Division">
                        {divisions?.map(division => (
                            <option key={division.id} value={division.name}>{division.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>League</FormLabel>
                    <Select value={selectedLeague} onChange={e => setSelectedLeague(e.target.value)} placeholder="Select League">
                        {leagues?.map(league => (
                            <option key={league.id} value={league.name}>{league.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Playoff Bracket</FormLabel>
                    <Select value={selectedPlayoffBracket} onChange={e => setSelectedPlayoffBracket(e.target.value)} placeholder="Select Playoff Bracket">
                        {playoffBrackets?.map(playoffBracket => (
                            <option key={playoffBracket.id} value={playoffBracket.name}>{playoffBracket.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Exclude From Standings
                    </FormLabel>
                    <Switch id="exclude-from-standings" isChecked={excludeFromStandings} onChange={e => setExcludeFromStandings(e.target.checked)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Mascot Name</FormLabel>
                    <Input value={mascotName} onChange={e => setMascotName(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Head Coach</FormLabel>
                    <Input value={headCoach} onChange={e => setHeadCoach(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Head Coach Phone</FormLabel>
                    <Input value={headCoachPhone} onChange={e => setHeadCoachPhone(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Head Coach Email</FormLabel>
                    <Input value={headCoachEmail} onChange={e => setHeadCoachEmail(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Shortened Team Name</FormLabel>
                    <Input value={shortenedTeamName} onChange={e => setShortenedTeamName(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Abbreviation</FormLabel>
                    <Input value={abbreviation} onChange={e => setAbbreviation(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Logo</FormLabel>
                    <Input value={logo} onChange={e => setLogo(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Website</FormLabel>
                    <Input value={website} onChange={e => setWebsite(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Twitter</FormLabel>
                    <Input value={twitter} onChange={e => setTwitter(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Instagram</FormLabel>
                    <Input value={instagram} onChange={e => setInstagram(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Facebook</FormLabel>
                    <Input value={facebook} onChange={e => setFacebook(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>YouTube</FormLabel>
                    <Input value={youTube} onChange={e => setYouTube(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Home Rink</FormLabel>
                    <Input value={homeRink} onChange={e => setHomeRink(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Main Color</FormLabel>
                    <Input type="color" value={mainColor} onChange={e => setMainColor(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel>Secondary Color</FormLabel>
                    <Input type="color" value={secondaryColor} onChange={e => setSecondaryColor(e.target.value)} />
                </FormControl>
                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default TeamForm;
