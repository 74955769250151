import React from 'react';
import {
    Box,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay,
    useBreakpointValue,
    useColorModeValue,
    VStack
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TeamSearchDropdown from "../components/EndUser/Search/TeamSearchDropdown";

const ResultsPage: React.FC = () => {
    const widgetHoverBg = useColorModeValue("#c4273c", "blue.300"); // Adjust colors for light/dark modes

    const Widget = ({ title, link }: { title: string; link: string }) => (
        <LinkBox as={Flex} textColor={"white"} alignItems="center" justifyContent="center" width="300px" height="30px" bg="#202b5f" _hover={{ bg: widgetHoverBg }} fontSize={14} fontWeight={800} letterSpacing={0}>
            <LinkOverlay href={link} isExternal>
                {title}
            </LinkOverlay>
        </LinkBox>
    );

    const columnBaseStyle = {
        flex: "1",
        border: "2px solid #007BFF",
        marginX: "10px",
        padding: "20px",
        width: "20%",
        bg: "#E9ECEF",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s"
    };

    const imgCarouselStyle = {
        width: 'auto',
        height: 'auto',
        maxHeight: '160px'
    };

    const columnFlexBasis = useBreakpointValue({ base: "100%", md: "auto" });
    const marginBottom = useBreakpointValue({ base: "20px", md: "0" });

    return (
        <Flex direction="row" justify="space-between" p="20px" wrap="wrap">
            <Box {...columnBaseStyle} flexBasis={columnFlexBasis} marginBottom={marginBottom}>
                {/* Content for other columns */}
            </Box>
            {/* Men's Hockey Column */}
            <Box {...columnBaseStyle} flexBasis={columnFlexBasis} marginBottom={marginBottom}>
                <VStack flex="1" m={2}>
                    <Heading size='md' mb={3}>Men's Hockey</Heading>
                    <Carousel showArrows={false} showIndicators={false} autoPlay interval={6000} transitionTime={700} showStatus={false} showThumbs={false} infiniteLoop useKeyboardArrows>
                        <div >
                            <img src="https://masshshockey.com/wp-content/uploads/2019/11/BC-High_featured.jpg" alt="Men's Hockey 1" style={imgCarouselStyle} />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/12/2018-19_Mens-D1_Duxbury.jpg" alt="Men's Hockey 2" style={imgCarouselStyle} />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/12/2018-19_Mens-D2_Canton.jpg" style={imgCarouselStyle} />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/12/2018-19_Mens-D3_Wachusett.jpg" alt="Men's Hockey 2"   style={imgCarouselStyle}/>
                        </div>
                        {/* More images */}
                    </Carousel>
                    <Widget title="MEN'S TEAMS" link="/link-1" />
                    <Widget title="MEN'S SCHEDULES" link="/link-2" />
                    <Widget title="MEN'S STANDINGS" link="/link-2" />
                    <Widget title="HNIBNEWS POLLS" link="/link-2" />
                </VStack>
            </Box>

            {/* Women's Hockey Column */}
            <Box {...columnBaseStyle} flexBasis={columnFlexBasis} marginBottom={marginBottom}>
                <VStack flex="1" m={2}>
                    <Heading size='md' mb={3} >Women's Hockey</Heading>
                    <Carousel showArrows={false} showIndicators={false} autoPlay interval={6000} transitionTime={700} showStatus={false} showThumbs={false} infiniteLoop useKeyboardArrows>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/11/Girls-D1-Final_Methuen-Tewksbury-vs-Austin-Prep-3276.jpg" alt="Women's Hockey 1" />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/12/2018-19_Womens-D2_Wellesley.jpg" alt="Women's Hockey 2" />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/11/Girls-D1-Final_Methuen-Tewksbury-vs-Austin-Prep-3276.jpg" alt="Women's Hockey 1" />
                        </div>
                        <div>
                            <img src="https://masshshockey.com/wp-content/uploads/2019/12/2018-19_Womens-D2_Wellesley.jpg" alt="Women's Hockey 3" />
                        </div>

                        {/* More images */}
                    </Carousel>
                    <Widget title="WOMEN'S TEAMS" link="/link-1" />
                    <Widget title="WOMEN'S SCHEDULES" link="/link-2" />
                    <Widget title="WOMEN'S STANDINGS" link="/link-2" />
                    <Widget title="HNIBNEWS POLLS" link="/link-2" />
                </VStack>
            </Box>

            {/* Other Columns */}
            <Box {...columnBaseStyle} flexBasis={columnFlexBasis} marginBottom={marginBottom}>
                <TeamSearchDropdown/>
                <TeamSearchDropdown/>
            </Box>
        </Flex>
    );
};

export default ResultsPage;
