import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import PlayerForm from "./PlayerForm";
import PlayerList from "./PlayerList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";

import {Client, Player, Team} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import RosterUploadButton from "../Teams/RosterUploadButton";

type PlayerManagementProps = {
    team: Team;
};
const PlayerManagement: React.FC<PlayerManagementProps> = ({ team }) => {
    const [players, setPlayers] = React.useState<Player[]>([]);
    const [selectedPlayer, setSelectedPlayer] = React.useState<Player | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();

    React.useEffect(() => {
        if (currentUser) {
            const url = team.id ? `/api/v1/admin/players/?teamId=${team.id}` : '/api/v1/admin/players';
            apiCall<Player[]>(url , currentUser)
                .then(playersData => setPlayers(playersData))
                .catch(error => console.error('Error fetching players:', error));
        }
    }, [currentUser]);

    const handleCreateUpdatePlayer = (player: { id?: number; firstName: string; lastName: string; email: string;
        phone: string; dateOfBirth: string; homeTown: string; heightFeet: string; heightInches: string; weight: string; number: number;
        position: string; lastTeam: string; client: Client; team: Team}) => {
        if (currentUser) {
            const method = player.id ? 'PUT' : 'POST';
            const url = player.id ? `/api/v1/admin/players/${player.id}` : '/api/v1/admin/players';

            apiCall<Player>(url, currentUser, method, player)
                .then(updatedPlayer => {
                    if(player.id) {
                        // If updating, find the index and replace the player in the list
                        const updatedPlayers = players.map(c => c.id === player.id ? updatedPlayer : c);
                        setPlayers(updatedPlayers);
                    } else {
                        // If creating, add the player to the list
                        setPlayers([...players, updatedPlayer]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating player:', error));
        }
    };


    const handleDeletePlayer = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/players/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setPlayers(players.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting player:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewPlayer = (id: number) => {
        const playerToView = players.find(player => player.id === id);
        setSelectedPlayer(playerToView || null);
        setMode("view");
    };
    // const handleViewPlayer = (id: number) => {
    //     if (currentUser) {
    //             apiCall<Player>(`/api/v1/admin/players/${id}`, currentUser, 'GET')
    //             .then(playerData => {
    //                 // Set player ID in the session
    //                 navigate('/player/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch player data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Text>Roster</Text>
                    <PlayerList
                        players={players}
                        onDelete={handleDeletePlayer}
                        onEdit={(id: number) => {
                            const playerToEdit = players.find(c => c.id === id) || null;
                            setSelectedPlayer(playerToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewPlayer}
                    />
                    <Button onClick={() => setMode("create")}>Add New Player</Button>
                    <RosterUploadButton teamId={team.id} setPlayers={setPlayers} /> <Button onClick={() => setMode('list')}>Back to List</Button>
                </>
            )}
            {mode === "create" && (
                <PlayerForm onSubmit={handleCreateUpdatePlayer} onCancel={() => setMode('list')} team={team} client={client!} />
            )}
            {mode === "edit" && selectedPlayer && (
                <PlayerForm onSubmit={handleCreateUpdatePlayer} onCancel={() => setMode('list')} team={team} client={client!} initialData={selectedPlayer} />
            )}
            {mode === "view" && selectedPlayer && (
                <Box>
                    <Heading>Player Details</Heading>
                    <Text><strong>First Name:</strong> {selectedPlayer.firstName}</Text>
                    <Text><strong>Last Name:</strong> {selectedPlayer.lastName}</Text>
                    <Text><strong>Email:</strong> {selectedPlayer.email}</Text>
                    <Text><strong>Phone:</strong> {selectedPlayer.phone}</Text>
                    <Text><strong>Date:</strong> {selectedPlayer.dateOfBirth}</Text>
                    <Text><strong>Home Town:</strong> {selectedPlayer.homeTown}</Text>
                    <Text><strong>Height (Feet):</strong> {selectedPlayer.heightFeet}</Text>
                    <Text><strong>Height (Inches):</strong> {selectedPlayer.heightInches}</Text>
                    <Text><strong>Weight:</strong> {selectedPlayer.weight}</Text>
                    <Text><strong>Number:</strong> {selectedPlayer.number}</Text>
                    <Text><strong>Position:</strong> {selectedPlayer.position}</Text>
                    <Text><strong>Last Team:</strong> {selectedPlayer.lastTeam}</Text>
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default PlayerManagement;
