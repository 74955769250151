import React from "react";
import {
    Grid,
    Text,
    Container,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    TabPanel,
    useBreakpointValue,
    HStack,
    Stack,
    TabIndicator,
    Box,
    Link,
    theme, VStack,
} from "@chakra-ui/react";
import TeamRecord from "./components/TeamRecord";
import TeamRoster from "./components/TeamRoster";
import TeamSchedule from "./components/TeamSchedule";
import TeamStandings from "./components/TeamStandings";
import { UserAvatar } from "./components/Card/UserAvatar";
import { CardContent } from "./components/Card/CardContent";
import { CardHeader } from "./components/Card/CardHeader";
import LinkButton from "./components/Card/LinkButton";
import { useParams } from "react-router-dom";
import { camelCaseToSentenceCase } from "../utils/textUtils";
import useApi from "./hooks/useApi";
import { Team } from "../utils/apiTypes";
import TeamStats from "./components/TeamStats";
const tabProps = {
    _focus: { outline: "none", boxShadow: "none" },
    _active: { bg: "transparent" },
    _selected: {
        color: "blue.500",
        bg: "transparent",
        borderColor: "transparent",
        fontWeight: 700
    },
};
interface TeamGroupType {
    id: number;
    name: string;
}

interface TeamGroup {
    id: number;
    name: string;
    teamGroupType: TeamGroupType;
}

interface TeamData {
    [key: string]: any;  // Other properties are dynamic
    teamGroups: TeamGroup[];
}
const DISPLAYED_FACETS = [
    "teamGroups",
    "headCoach",
    "headCoachPhone",
    "headCoachEmail",
    "homeRink",
    "website",
    "twitter",
    "instagram",
    "facebook",
    "youTube",
]
function isURL(str: string | null | undefined): boolean {
    if (!str) return false;
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(str);
}
const linkStyle = {
    padding: 4,
    fontWeight: 'bold'
}
const TeamDetailPage = () => {
    // Replace with actual team data
    const { id } = useParams();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { data: teamData } = useApi<TeamData>(`api/v1/client/10/teams/${id}`);
    if (!teamData) {
        return (
            <div>loading</div>
        )

    }
    return (
        <Grid
            templateColumns={["1fr", "1fr 3fr 1fr"]}
            gap={6}
        >
            <Box gridColumn={["1 / -1", "1 / 2"]} p={4} order={[1, 0]}>
                <a href="https://www.gipper.com/partner-demo-request?Partnerhnibbutton=&utm_source=button&utm_medium=Partner&utm_campaign=hnib&utm_content=partner&utm_term=hnib_button_masshockey_website_logo&query=Partnerhnibbutton"
                   target="_blank" rel="noopener noreferrer" style={{margin: '10px'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Gipper_MassHSHockey_300x300.png"
                         alt="Gipper"/>
                </a>
                <a href="https://uoduckshockey.com/recruits" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/UOREGON-HOCKEY-AD.jpg"
                         alt="Oregon"/>
                </a>
            </Box>
            <Box gridColumn={["1 / -1", "2 / 3"]} p={4} order={[0, 0]} >
                {/* Main Content */}
                <Box
                    maxWidth={["90%", "80%", "70%", "60%", "1280px"]}
                    marginTop={3}
                    position="relative"
                >
            <Grid
                justifyItems="center"
                alignItems="center"
                templateColumns={isMobile ? '100%' : "100px 1fr auto"}
                templateRows={isMobile ? "90px auto 1fr" : "1fr"}
                gap={8}
                p={6}
                pt={isMobile ? 0 : 6}
            >
                <UserAvatar
                    name={teamData.name}
                    src={teamData.logo || undefined}
                    borderRadius='full'
                    boxSize={isMobile ? '90px' : '100px'}
                    padding={isMobile ? '5px' : '0'}
                    background="var(--avatar-bg)"
                />
                <CardContent >
                    <CardHeader display="block" textAlign={isMobile ? 'center' : 'left'} title={teamData.name} />
                    <Stack alignItems={{ base: 'center', md: 'flex-start' }} spacing="0" mt="2">
                        {Object.keys(teamData).filter(key => DISPLAYED_FACETS.includes(key)).map((key) => {
                            const value = teamData[key];

                            // Handling the teamGroups array
                            if (key === 'teamGroups' && Array.isArray(value)) {
                                return (
                                    <VStack align={isMobile ? "center" : "start"} key={key} spacing="0">
                                        {value.map((teamGroup, index) => (
                                            <HStack key={index} fontSize="sm">
                                                {teamGroup.teamGroupType.name === "League" || teamGroup.teamGroupType.name === "Division" ?
                                                    <><Text as="strong">{teamGroup.teamGroupType.name}:</Text><Link href={`/standings?teamGroup=${teamGroup.id}`}>
                                                        <Text as="strong">{teamGroup.name}</Text>
                                                    </Link></>
                                                    :
                                                    <Text>{teamGroup.name}</Text>
                                                }
                                            </HStack>
                                        ))}
                                    </VStack>
                                );
                            }
                            // Handling strings and other types
                            return (
                                <HStack key={key} fontSize="sm">
                                    {isURL(value.toString()) ?
                                        <Link href={value.toString()}>{camelCaseToSentenceCase(key)}</Link>
                                        :
                                        <><Text as="strong">{camelCaseToSentenceCase(key)}:</Text> <Text>{value}</Text></>
                                    }
                                </HStack>
                            );
                        })}
                    </Stack>
                </CardContent>
            </Grid>
            <Box borderRadius={theme.radii.md} marginBottom={4} padding={4} backgroundColor={theme.colors.gray[300]}>
                <Link {...linkStyle} href="#schedule">Schedule</Link>
                <Link {...linkStyle} href="#standings">Record</Link>
                <Link {...linkStyle} href="#roster">Roster</Link>
                <Link {...linkStyle} href="#stats">Stats</Link>
            </Box>
            <Box mb={6} id="schedule">
                <TeamSchedule />
            </Box>
            <Box mb={6} paddingTop={6} id="standings">
                <TeamStandings />
            </Box>
            <Box mb={6} paddingTop={6} id="roster">
                <TeamRoster />
            </Box>
            <Box mb={6} paddingTop={6} id="stats">
                <TeamStats />
            </Box>
            {/* <TeamRecord /> */}
        </Box >
            </Box>
            <Box gridColumn={["1 / -1", "3 / 4"]} p={4} order={[1, 0]}>
                <a href="https://laurastamm.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Laura-Stamm_2021_300px.jpg"
                         alt="Laura Stamm"/>
                </a>
                <a href="https://nswingsgirls.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/NS_Wings_Girls_2023AD-1.png"
                         alt="NS Wings"/>
                </a>
                <a href="http://dynamiksports.com/" target="_blank" rel="noopener noreferrer"
                   style={{margin: '10px', verticalAlign: 'top'}}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Dynamik-300x250.jpg"
                         alt="Dynamic Sports"/>
                </a>
            </Box>
        </Grid>
    )
}

export default TeamDetailPage;
