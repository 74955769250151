import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import SeasonStatsForm from "./SeasonStatsForm";
import SeasonStatsList from "./SeasonStatsList";
import {apiCall} from "../../../utils/apiUtility";
import {useAuth} from "../../Auth/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";

import {Client, SeasonStats, Team} from "../../../utils/apiTypes";
import {useClient} from "../../Contexts/ClientContext";
import SeasonStatsUploadButton from "../Teams/StatsUploadButton";

type SeasonStatsManagementProps = {
    team: Team;
};
type SeasonStatsData = {
    id?: number;
    firstName: string;
    lastName: string;
    number: number | undefined;
    gamesPlayed: number;
    numberOfGoals: number;
    numberOfAssists: number;
    penaltyMinutes: number;
    shots: number;
    goalsAgainst: number;
    client: Client;
    team: Team;
}

const SeasonStatsManagement: React.FC<SeasonStatsManagementProps> = ({ team }) => {
    const [seasonStatsArray, setSeasonStatsArray] = React.useState<SeasonStats[]>([]);
    const [selectedSeasonStats, setSelectedSeasonStats] = React.useState<SeasonStats | null>(null);
    const [mode, setMode] = React.useState("list"); // "list", "create", "edit"
    const { client, setClient } = useClient();
    const { currentUser } = useAuth();

    React.useEffect(() => {
        if (currentUser) {
            const url = team.id ? `/api/v1/admin/seasonstatline/?teamId=${team.id}` : '/api/v1/admin/seasonStatsArray';
            apiCall<SeasonStats[]>(url , currentUser)
                .then(seasonStatsArrayData => setSeasonStatsArray(seasonStatsArrayData))
                .catch(error => console.error('Error fetching seasonStatsArray:', error));
        }
    }, [currentUser]);

    const handleCreateUpdateSeasonStats = (seasonStats: SeasonStatsData) => {
        if (currentUser) {
            const method = seasonStats.id ? 'PUT' : 'POST';
            const url = seasonStats.id ? `/api/v1/admin/seasonstatline/${seasonStats.id}` : '/api/v1/admin/seasonstatline';

            apiCall<SeasonStats>(url, currentUser, method, seasonStats)
                .then(updatedSeasonStats => {
                    if(seasonStats.id) {
                        // If updating, find the index and replace the seasonStats in the list
                        const updatedSeasonStatsArray = seasonStatsArray.map(c => c.id === seasonStats.id ? updatedSeasonStats : c);
                        setSeasonStatsArray(updatedSeasonStatsArray);
                    } else {
                        // If creating, add the seasonStats to the list
                        setSeasonStatsArray([...seasonStatsArray, updatedSeasonStats]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating seasonStats:', error));
        }
    };


    const handleDeleteSeasonStats = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/seasonstatline/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setSeasonStatsArray(seasonStatsArray.filter(c => c.id !== id));
                    setMode("list");
                })
                .catch(error => console.error('Error deleting seasonStats:', error));
        }
    };

    const navigate = useNavigate();
    const handleViewSeasonStats = (id: number) => {
        const seasonStatsToView = seasonStatsArray.find(seasonStats => seasonStats.id === id);
        setSelectedSeasonStats(seasonStatsToView || null);
        setMode("view");
    };
    // const handleViewSeasonStats = (id: number) => {
    //     if (currentUser) {
    //             apiCall<SeasonStats>(`/api/v1/admin/seasonStatsArray/${id}`, currentUser, 'GET')
    //             .then(seasonStatsData => {
    //                 // Set seasonStats ID in the session
    //                 navigate('/seasonStats/');
    //             })
    //             .catch(error => {
    //                 console.error("Failed to fetch seasonStats data", error);
    //             });
    //     }
    // };
    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Text>Stats</Text>
                    <SeasonStatsList
                        seasonStatsArray={seasonStatsArray}
                        onDelete={handleDeleteSeasonStats}
                        onEdit={(id: number) => {
                            const seasonStatsToEdit = seasonStatsArray.find(c => c.id === id) || null;
                            setSelectedSeasonStats(seasonStatsToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewSeasonStats}
                    />
                    <Button onClick={() => setMode("create")}>Add New SeasonStats</Button>
                    <SeasonStatsUploadButton teamId={team.id} setSeasonStatsArray={setSeasonStatsArray} /> <Button onClick={() => setMode('list')}>Back to List</Button>
                </>
            )}
            {mode === "create" && (
                <SeasonStatsForm onSubmit={handleCreateUpdateSeasonStats} onCancel={() => setMode('list')} team={team} client={client!} />
            )}
            {mode === "edit" && selectedSeasonStats && (
                <SeasonStatsForm onSubmit={handleCreateUpdateSeasonStats} onCancel={() => setMode('list')} team={team} client={client!} initialData={selectedSeasonStats} />
            )}
            {mode === "view" && selectedSeasonStats && (
                <Box>
                    <Heading>SeasonStats Details</Heading>
                    <Text><strong>Number:</strong> {selectedSeasonStats.number}</Text>
                    <Text><strong>Name:</strong> {selectedSeasonStats.firstName} {selectedSeasonStats.lastName}</Text>
                    <Text><strong>GP:</strong> {selectedSeasonStats.gamesPlayed}</Text>
                    <Text><strong>Goals:</strong> {selectedSeasonStats.numberOfGoals}</Text>
                    <Text><strong>Assists:</strong> {selectedSeasonStats.numberOfAssists}</Text>
                    <Text><strong>Points:</strong> {selectedSeasonStats.numberOfGoals + selectedSeasonStats.numberOfAssists}</Text>
                    <Text><strong>PIM:</strong> {selectedSeasonStats.penaltyMinutes}</Text>
                    <Text><strong>Shots:</strong> {selectedSeasonStats.shots}</Text>
                    <Text><strong>GoalsAgainst:</strong> {selectedSeasonStats.goalsAgainst}</Text>
                    <Text><strong>Save PCT:</strong> {(selectedSeasonStats.shots - selectedSeasonStats.goalsAgainst) / selectedSeasonStats.shots}</Text>
                    <Text><strong>GAA:</strong> {selectedSeasonStats.goalsAgainst / selectedSeasonStats.gamesPlayed}</Text>
                    <Button onClick={() => setMode('list')}>Back to List</Button>
                </Box>
            )}

        </VStack>
    );
}

export default SeasonStatsManagement;
