import { Button, Box, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import React from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {User} from "../../../utils/apiTypes";

function UserList({
                        users,
                        onDelete,
                        onEdit,
                        onView
                    }: {
    users: User[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
}) {
    return (
        <Box>
            <Table variant="striped" colorScheme="teal">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Email</Th>
                        <Th>First Name</Th>
                        <Th>Last Name</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map(user => (
                        <Tr key={user.id}>
                            <Td>{user.id}</Td>
                            <Td>{user.email}</Td>
                            <Td>{user.firstName}</Td>
                            <Td>{user.lastName}</Td>
                            <Td>
                                <Button onClick={() => onView(user.id!)}>View</Button>
                                <Button onClick={() => onEdit(user.id!)}>Edit</Button>
                                <DeleteConfirmation
                                    title="Delete User"
                                    message="Are you sure you want to delete this user?"
                                    onDelete={() => onDelete(user.id!)}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default UserList;
