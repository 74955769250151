// components/LocationForm.tsx

import { Button, FormControl, FormLabel, Input, Box, Text } from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";
import {Client, SubLocation} from "../../../utils/apiTypes";
import {circOut} from "framer-motion";
import LocationUploadButton from "./LocationUploadButton";

type LocationFormProps = {
    onSubmit: (location: LocationData) => void;
    initialData?: LocationData;
    onCancel: () => void;
};

type LocationData = {
    id?: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    client: Client;
};

const LocationForm: React.FC<LocationFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const [name, setName] = React.useState(initialData?.name || "");
    const [address1, setAdress1] = React.useState(initialData?.address1 || "");
    const [address2, setAddress2] = React.useState(initialData?.address2 || "");
    const [city, setCity] = React.useState(initialData?.city || "");
    const [state, setState] = React.useState(initialData?.state || "");
    const [postalCode, setPostalCode] = React.useState(initialData?.postalCode || "");
    const [country, setCountry] = React.useState(initialData?.country || "");

    const validate = (): boolean => {
        if (!name.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        if (!address1.trim()) {
            setValidationError("Address1 cannot be blank");
            return false;
        }
        if (!city.trim()) {
            setValidationError("City cannot be blank");
            return false;
        }
        if (!state.trim()) {
            setValidationError("State cannot be blank");
            return false;
        }
        if (!postalCode.trim()) {
            setValidationError("Postal Code cannot be blank");
            return false;
        }
        if (!country.trim()) {
            setValidationError("Country cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: LocationData = {
                ...initialData,
                name: name,
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                postalCode: postalCode,
                country: country,
                client: client!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Address Line 1</FormLabel>
                    <Input value={address1} onChange={e => setAdress1(e.target.value)} />
                </FormControl>

                <FormControl>
                    <FormLabel>Address Line 2</FormLabel>
                    <Input value={address2} onChange={e => setAddress2(e.target.value)} />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>City</FormLabel>
                    <Input value={city} onChange={e => setCity(e.target.value)} />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>State</FormLabel>
                    <Input value={state} onChange={e => setState(e.target.value)} />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Zip Code</FormLabel>
                    <Input value={postalCode} onChange={e => setPostalCode(e.target.value)} />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Country</FormLabel>
                    <Input value={country} onChange={e => setCountry(e.target.value)} />
                </FormControl>

                {validationError && <Text color="red.500">{validationError}</Text>}

                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default LocationForm;
