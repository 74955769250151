import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex, Heading,
    IconButton,
    LinkBox,
    LinkOverlay,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {Page, Team} from "../../../utils/apiTypes";
import { apiCall } from "../../../utils/apiUtility";
import { useAuth } from "../../Auth/AuthContext";
import { EditIcon } from "@chakra-ui/icons";
import {teamsQueryMap} from "../../../utils/queryMapUtil";
import {useClient} from "../../Contexts/ClientContext";
import {useSeason} from "../../Contexts/SeasonContext";
import {useOrganization} from "../../Contexts/OrganizationContext";

type TeamGroupTeamsListProps = {
    teamGroupId: number,
    teamGroupName: string,
    onBackToList: () => void
};
const TeamGroupTeamsList: React.FC<TeamGroupTeamsListProps> = ({ teamGroupId, teamGroupName, onBackToList }) => {
    const [teams, setTeams] = useState<Team[]>([]);
    const { currentUser } = useAuth();
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { organization } = useOrganization();
    const queryMap = teamsQueryMap(client, organization, season)
    useEffect(() => {
        queryMap.teamGroupId = teamGroupId
        if (currentUser && teamGroupId) {
            apiCall<Page<Team>>('/api/v1/admin/teams', currentUser, "GET", null, queryMap)
                .then(teamData => setTeams(teamData.content))
                .catch(error => console.error('Error fetching teams:', error));
        }
    }, [currentUser, teamGroupId]);

    return (

        <Box>
            <Heading>{teamGroupName}</Heading>
            <Table variant="striped" colorScheme="teal" size="sm">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {teams?.map(team => (
                        <Tr key={team.id}>
                            <Td>
                                <LinkBox>
                                    <LinkOverlay href="#">
                                        {team.name}
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Button mt={4} onClick={onBackToList}>Back to TeamGroups</Button>
        </Box>
    );
};

export default TeamGroupTeamsList;
