import {Flex, FormLabel, Link} from "@chakra-ui/react"
type Toptions = "men" | "women";
interface IGenderLinks {
    gender: Toptions;
    basePath: string;
}

export const GenderLinks = ({ gender, basePath }: IGenderLinks) => {



    const buttonLinkStyles = {
        display: 'inline-block',
        px: '4',
        py: '2',
        borderRadius: 'md',
        textDecoration: 'none',
        borderStyle: 'solid',
        borderWidth: '1px'
    };

    const disabledStyle = {
        ...buttonLinkStyles,
        bg: 'white',
        color: 'gray.400',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        borderColor: 'gray.400'
    }
    const enabledStyles = {
        ...buttonLinkStyles,
        bg: 'blue.600',
        color: 'white',
        borderColor: 'transparent'

    };
    return (
        <FormLabel>Select Gender
        <Flex gap={2} alignItems='center'>
            <Link {...gender === "men" ? disabledStyle : enabledStyles} href={`${basePath}/men`}>Boys</Link>
            <Link {...gender === "women" ? disabledStyle : enabledStyles} href={`${basePath}/women`}>Girls</Link>
        </Flex>
        </FormLabel>
    )

}
